import { useFormik } from "formik"
import React, { ChangeEvent, FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

import DropdownMenu from "../inputs/DropdownMenu"
import SearchInput from "../search/SearchInput"
import gameService from "services/game/game.service"
import Modal from "./Modal"

interface FormValuesType {
  game?: string
  provider: string
}

interface IProps {
  data?: any
  open: boolean
  handler: any
  onSubmit: (values: FormValuesType) => void
}

const validationSchema = Yup.object().shape({
  game: Yup.string().required()
})

const ModalFreeSpinGame: FC<IProps> = (props) => {
  const { t } = useTranslation("landing", { useSuspense: false })

  const { data, open, handler, onSubmit } = props

  const [query, setQuery] = useState<string>()
  const [provider, setProvider] = useState<string>()

  const [games, setGames] = useState<any[]>([])
  const [providers, setproviders] = useState<any[]>([])

  useEffect(() => {
    const onLoad = async (): Promise<void> => {
      const providersResponse = await gameService.getGameProviders()
      if (providersResponse) setproviders(providersResponse)
    }

    if (open) {
      onLoad()
    }

    return () => {
      formik.setTouched({})
    }
  }, [open])

  useEffect(() => {
    onLoadGames()
  }, [provider, query])

  const onChangeProvider = (event: ChangeEvent<HTMLSelectElement>): void => {
    setProvider(event?.target?.value)
    formik.handleChange(event)
  }

  const onLoadGames = async (): Promise<void> => {
    const gamesResponse = await gameService.getFreeSpinGames(provider, query)
    if (gamesResponse) setGames(gamesResponse)
  }

  useEffect(() => {
    if (data) {
      formik.setValues(data)
    }
  }, [data])

  const formik = useFormik<FormValuesType>({
    initialValues: {
      game: "",
      provider: ""
    },
    onSubmit: async (values, formikHelpers): Promise<void> => {
      formikHelpers.setTouched({})
      await onSubmit(values)
      handler()
    },
    validationSchema
  })

  return (
    <Modal open={open} handler={handler}>
      <div className="relative flex flex-col justify-between modal-box">
        <div className="w-full grid grid-cols-1 items-center gap-12">
          <div className="w-full grid grid-cols-2 gap-6">
            <SearchInput title={t("search_game")} onChange={setQuery} />
            <DropdownMenu id="provider" options={providers} value={formik.values.provider} onChange={onChangeProvider} />
            <DropdownMenu
              id="game"
              options={games}
              value={formik.values.game}
              onChange={formik.handleChange}
              invalid={!!(formik.touched.game && formik.errors.game)}
              altLabelOne={formik.touched.game ? formik.errors.game : ""}
              noAllValueText
            />
          </div>
        </div>
        <div className="modal-action">
          <button className="btn btn-secondary" onClick={handler}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={formik.submitForm}>
            Save
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalFreeSpinGame
