import React, { useEffect } from "react"

const ModalBg = ({ handler }: { handler: any }) => {
  useEffect(() => {
    const escHandler = (event: any) => {
      if (event.key === "Escape") {
        handler(!open)
      }
    }

    document.addEventListener("keydown", escHandler, false)

    return () => {
      document.removeEventListener("keydown", escHandler, false)
    }
  }, [])

  return (
    <>
      <button
        onClick={() => handler(false)}
        className="bg-grey-dark fixed top-[-100px] left-0 z-10 h-[calc(100vh+200px)] w-screen opacity-50"
      ></button>
    </>
  )
}

export default ModalBg
