import useApi, { parseError } from "util/api"
import { REQ } from "constants/api/requests"
import { IResponse, IResponsePayload } from "constants/interfaces"
import { formatRewardGameStatus } from "util/formatter"
import { BonusType } from "types/api"

export const BonusService = {
  async getBonusesList(
    page: number,
    pageSize: number,
    id: number | undefined,
    { q, type, active, tenant }: { q?: string; type?: BonusType; active?: boolean; tenant?: string },
    sortRow?: string,
    sortOrder?: string
  ) {
    try {
      const params = {
        page,
        pageSize,
        q,
        type,
        tenant,
        active,
        sortRow,
        sortOrder
      }

      const { data } = await useApi.get<IResponse<any>>(REQ.ADMIN_DASHBOARD.BONUSE.LIST, { params })

      if (data?.success) {
        return data?.response
      }
    } catch (error: any) {
      parseError(error)
    }
  },

  async addBonus(payload: any) {
    const { data } = await useApi.post<IResponse<any>>(REQ.ADMIN_DASHBOARD.BONUSE.CREATE, payload)

    if (data?.success) {
      return data?.response
    }
  },

  async editBonus(id: string, payload: any) {
    const { data } = await useApi.put<IResponse<any>>(REQ.ADMIN_DASHBOARD.BONUSE.UPDATE.replace(":id", id), payload)

    if (data?.success) {
      return data?.response
    }
  }
}
