import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import Table from "components/tables/Table"
import SearchInput from "components/search/SearchInput"
import ModalPromoCode from "components/modals/ModalPromoCode"
import { tablePromoCodesColumns } from "constants/promo-codes"
import promoCodeService from "services/promoCode/promoCode.service"
import { CreatePromoCodePayload, EditPromoCodePayload } from "services/promoCode/type"
import { SortOrderBy, SortOrderType } from "constants/eums"
import { BonusService } from "services/bonus/bonus.service"
import { BonusType } from "types/api"
import { UserRole } from "services/user/type"
import { useProps } from "contexts/PropsContext"

const PromoCodes: FC = () => {
  const { t } = useTranslation(["landing"], { useSuspense: false })
  const { user } = useProps()

  const [editRow, setEditRow] = useState(null)
  const [code, setCode] = useState<string>("")
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false)
  const [tableUpdatedAt, setTableUpdatedAt] = useState<Date | number>(Date.now())
  const [promoBonusList, setPromoBonusList] = useState<any>([])

  useEffect(() => {
    const getPromoBonusList = async () => {
      const res = await BonusService.getBonusesList(1, 100, undefined, { type: BonusType.PROMO, active: true })
      if (res.data) {
        setPromoBonusList(res.data)
      }
    }
    getPromoBonusList()
  }, [])

  const onEditRow = (row: any): void => {
    setEditRow(row)
    setOpenEditModal(true)
  }

  const editRowHandler = (): void => {
    setEditRow(null)
    setOpenEditModal(false)
  }

  const createPromoCodeHandler = (): void => {
    setOpenCreateModal(false)
  }

  const onEditModalSubmit = async ({ id, ...data }: EditPromoCodePayload & { id: string }): Promise<void> => {
    try {
      await promoCodeService.editPromoCode(id, data)
      setTableUpdatedAt(Date.now())
      toast.success("Promo code updated successfully")
    } catch(err) {
      toast.error("Failed")
    }
  }

  const onCreateModalSubmit = async ({ id: _, ...data }: CreatePromoCodePayload & { id: null | string }): Promise<void> => {
    try {
      await promoCodeService.createPromoCode(data)
      setTableUpdatedAt(Date.now())
      toast.success("Promo code added successfully")
    } catch (err) {
      toast.error("Failed")
    }
  }

  const actions = [
    {
      name: t("edit"),
      handler: onEditRow
    }
  ]

  return (
    <div className="grid w-full gap-8 p-8 grid-col-1">
      <div className="flex flex-row items-end justify-between">
        <div className="max-w-sm">
          <SearchInput title={t("promo_code")} onChange={setCode} />
        </div>
        {
          user.role === UserRole.ADMIN ? (
            <button className="btn btn-primary" onClick={() => setOpenCreateModal(true)}>
              {t("create")}
            </button>
          ) : null
        }
      </div>
      <Table
        title={t("promo_codes")}
        handler={promoCodeService.getPromoCodesTable}
        columns={tablePromoCodesColumns}
        actions={actions}
        updatedAt={tableUpdatedAt}
        filters={{ code }}
        defaultSortOrder={SortOrderType.ASC}
        defaultSortOrderBy={SortOrderBy.ID}
        onRowClick={(row: any) => onEditRow(row)}
      />
      <ModalPromoCode
        open={openEditModal}
        data={editRow}
        handler={editRowHandler}
        onSubmit={onEditModalSubmit}
        promoBonuses={promoBonusList}
      />
      <ModalPromoCode
        open={openCreateModal}
        handler={createPromoCodeHandler}
        onSubmit={onCreateModalSubmit}
        promoBonuses={promoBonusList}
      />
    </div>
  )
}

export default PromoCodes
