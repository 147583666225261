import { REQ } from "constants/api/requests"
import { IResponse } from "constants/interfaces"
import useApi from "util/api"
import { GetAdminActivityResponse, IActivityService } from "./type"

export class ActivityService implements IActivityService {
  public async getAdminActivity(
    page: number,
    pageSize: number,
    id: number | undefined,
    { q, createdAt }: { q: string, createdAt: (Date | null)[] },
  ): Promise<GetAdminActivityResponse | void> {
    const params = {
      page,
      pageSize,
      query: q || null,
      createdAt,
    }

    const { data } = await useApi.get<IResponse<GetAdminActivityResponse>>(REQ.ADMIN_DASHBOARD.ACTIVITY, { params })

    if (data?.success) {
      return data?.response
    }
  }
}

export default new ActivityService()
