import { FC } from "react"
import Modal from "./Modal"
import { useTranslation } from "react-i18next"
import InputField from "components/inputs/InputField"
import FileViewer from "components/fileviewer/FileViewer"

interface IProps {
    data?: any
    open: boolean
    handler: any
    declineHandler: any
    approveHandler: any
  }

const ModalKYCFiles: FC<IProps> = (props) => {
    const { data, open, handler, declineHandler, approveHandler } = props
    const { t }: { t: any } = useTranslation(["landing"], { useSuspense: false })

    return (
        <Modal open={open} handler={handler}>
            <div className="relative flex flex-col justify-between modal-box max-w-full">
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    <InputField
                        title="First Name"
                        value={data?.user.personal_profile.first_name}
                        disabled 
                    />
                    <InputField
                        title="Last Name"
                        value={data?.user.personal_profile.last_name}
                        disabled 
                    />
                    <InputField
                        title="Birthday"
                        value={data?.user.personal_profile.dob}
                        disabled 
                    />
                    <InputField
                        title="Country"
                        value={data?.user.personal_profile.country}
                        disabled 
                    />
                    <InputField
                        title="Postcode"
                        value={data?.user.personal_profile.zip}
                        disabled 
                    />
                    <InputField
                        title="City"
                        value={data?.user.personal_profile.city}
                        disabled 
                    />
                    <InputField
                        title="Address"
                        value={data?.user.personal_profile.address}
                        disabled 
                    />
                    <InputField
                        title="Address2"
                        value={data?.user.personal_profile.address2}
                        disabled 
                    />

                    <FileViewer 
                        title="IDCard Front"
                        path={data?.user.kyc_files.img_idcard_front}
                    />
                    <FileViewer 
                        title="IDCard Back"
                        path={data?.user.kyc_files.img_idcard_back}
                    />
                    <FileViewer 
                        title="Proof Address"
                        path={data?.user.kyc_files.doc_proof_address}
                    />
                    <FileViewer 
                        title="Additional Document"
                        path={data?.user.kyc_files.doc_add_verify}
                    />
                </div>
                <div className="modal-action">
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            handler();
                            declineHandler(data);
                        }}
                    >
                        {t("decline")}
                    </button>
                    <button 
                        className="btn btn-secondary"
                        onClick={() => {
                            handler();
                            approveHandler(data);
                        }}
                    >
                        Approve
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default ModalKYCFiles
