import { GetSingleUser } from "services/user/type"
import { formatBoolean, formatDate } from "util/formatter"
import { CardWrapper } from "./CardWrapper"
import { cardGatewayTitles } from "constants/deposit-gateway"
import { CardGateway } from "types/api"

type UserGeneralCardProps = {
  user: GetSingleUser
}
export const UserGeneralCard = ({ user }: UserGeneralCardProps) => {
  return (
    <CardWrapper>
      <span>
        ID: <span className="text-accent">{user.id}</span>
      </span>
      <span>
        Tenant: <span className="text-accent">{user.tenant_id}</span>
      </span>
      {/* <span>
        Discord: <span className="text-accent">{user.discord_name}</span>
      </span> */}
      <span>
        Email: <span className="text-accent">{user.email_address}</span>
      </span>
      <span>
        VIP level: <span className="text-accent">{user.level}</span>
      </span>
      <span>
        Role: <span className="text-accent">{user.role}</span>
      </span>
      <span>
        Is referred: <span className="text-accent">{formatBoolean(user.is_referred)}</span>
      </span>
      <span>
        Created at: <span className="text-accent">{formatDate(user.created_at)}</span>
      </span>
      <span>
        KYC: <span className="text-accent">{formatBoolean(user.kyc)}</span>
      </span>
      <span>
        Deposit Gateway: <span className="text-accent">{cardGatewayTitles[user.next_card_gateway ?? CardGateway.SP]}</span>
      </span>
    </CardWrapper>
  )
}
