import { IResponse } from "constants/interfaces"
import useApi from "util/api"
import { REQ } from "constants/api/requests"
import { GetKYCFilesResponse, IKYCService } from "./type"

class KYCService implements IKYCService {
  public async getKYCFiles(
    page: number,
    pageSize: number,
    id: number | undefined,
    { status, createdAt }: { status?: string, createdAt?: (Date | null)[] },
    sortRow?: string,
    sortOrder?: string
  ): Promise<GetKYCFilesResponse | void> {
    const params = {
      page,
      pageSize,
      sortRow,
      sortOrder,
      status: status || null,
      createdAt: createdAt || null,
    }

    const { data } = await useApi.get<IResponse<GetKYCFilesResponse>>(
      REQ.ADMIN_DASHBOARD.KYC_FILES.GET,
      { params }
    )

    if (data?.success) {
      // data.response.data = data?.response?.data.map((rg: any) => ({ ...rg, status: formatRewardGameStatus(rg) }))
      return data?.response
    }
  }
  
  public async updateKYCFilesStatus(id: string, status: string, reason?: string): Promise<any> {
    const { data } = await useApi.post(REQ.ADMIN_DASHBOARD.KYC_FILES.UPDATE, { id, status, reason })

    if (data?.success) {
      return data?.response
    }
  }

  public async getKYCFilesCount(): Promise<any> {
    const { data } = await useApi.get(REQ.ADMIN_DASHBOARD.KYC_FILES.PENDING_COUNT)

    if (data?.success) {
      return data?.response
    }
  }
}

export default new KYCService()
