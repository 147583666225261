import { REQ } from "constants/api/requests"
import { IResponse } from "constants/interfaces"
import useApi from "util/api"
import { GetTransactionsTableResponse, ITransactionService, TransactionActionResponse } from "./type"
class TransactionService implements ITransactionService {
  public async getTransactionsTable(
    page: number,
    pageSize: number,
    id: number | undefined,
    { type, status, coin, createdAt }: { type: string; status: string; coin?: string, createdAt?: (Date | null)[] },
    sortRow?: string,
    sortOrder?: string
  ): Promise<GetTransactionsTableResponse | void> {
    const params = {
      page,
      pageSize,
      type: type || null,
      status: status || null,
      createdAt: createdAt || null,
      sortRow,
      sortOrder,
      ...(coin && { coin })
    }
    const { data } = await useApi.get<IResponse<GetTransactionsTableResponse>>(REQ.ADMIN_DASHBOARD.TRANSACTIONS.GET, { params })

    if (data?.success) {
      return data?.response
    }
  }

  public async confirmTransaction(id: string): Promise<boolean | void> {
    const { data } = await useApi.post<IResponse<TransactionActionResponse>>(REQ.ADMIN_DASHBOARD.TRANSACTIONS.CONFIRM, { id })
    if (data.success) {
      return data?.response?.success
    }
  }

  public async declineTransaction(id: string, reason?: string): Promise<boolean | void> {
    const { data } = await useApi.post<IResponse<TransactionActionResponse>>(REQ.ADMIN_DASHBOARD.TRANSACTIONS.DECLINE, { id, reason })

    if (data.success) {
      return data?.response?.success
    }
  }

  public async approveBankTransfer(id: string, status: string): Promise<boolean | void> {
    const { data } = await useApi.post<IResponse<TransactionActionResponse>>(REQ.ADMIN_DASHBOARD.BANK_TRANSFER.APPROVE, { id, status })
    if (data.success) {
      return data?.response?.success
    }
  }
}

export default new TransactionService()
