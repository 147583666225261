import { useMemo, useState } from "react"
import clsx from "clsx"
import userService from "services/user/user.service"
import Table from "components/tables/Table"
import { tableComments } from "constants/data"
import { GetSingleUser } from "services/user/type"
import SingleOptTimeRangePicker from "components/date/SingleOptTimePeriodPicker"
import { CardWrapper } from "./CardWrapper"
import CommentsCardAddInput from "./CommentsCardAddInput"

export enum EnumCommentsTab {
  ACCOUNT = "ACCOUNT",
  KYC = "KYC",
  GENERAL = "GENERAL"
}

type CommentsCardProps = {
  user: GetSingleUser
  onSuccess: () => void
}

export const CommentsCard = ({ user, onSuccess }: CommentsCardProps) => {
  const [commentCategory, setCommentCategory] = useState<EnumCommentsTab>(EnumCommentsTab.ACCOUNT)
  const [timeframe, setTimeframe] = useState<(Date | null)[]>([])

  const tableFilters = useMemo(() => ({ category: commentCategory }), [commentCategory])

  const handleChangeTimeFrame = (dates: (Date | null)[]) => setTimeframe(dates)

  const getCommentCountByCategory = (category: string) => {
    const count = user.comments.filter((item) => item.category === category).length
    return count > 100 ? "99+" : count
  }

  return (
    <CardWrapper>
      <p>Comments</p>
      <div className="flex items-center pt-5">
        <div className="tabs">
          {Object.keys(EnumCommentsTab).map((tab) => (
            <a
              key={`user-tab-${tab}`}
              onClick={() => setCommentCategory(tab as EnumCommentsTab)}
              className={clsx("tab tab-bordered", {
                "tab-active": commentCategory === tab
              })}
            >
              {tab} ({getCommentCountByCategory(tab)})
            </a>
          ))}
        </div>
      </div>
      <CommentsCardAddInput userId={user.id} category={commentCategory} onSuccess={onSuccess} />
      <div className="max-w-md">
        <SingleOptTimeRangePicker onTimeframeChange={handleChangeTimeFrame} />
      </div>
      <Table
        title=""
        handler={userService.getUserComments}
        filters={{ ...tableFilters, createdAt: timeframe }}
        id={user.id}
        columns={tableComments}
      />
    </CardWrapper>
  )
}
