export const clearStorage = (): void => {
  localStorage.clear()
}

export const removeStorage = (name: string): void => {
  localStorage.removeItem(name)
}

export const getterAuthenticated = (): boolean => {
  return localStorage.getItem("ga-authenticate") === "true" || false
}

export const setterAuthenticated = (bool: boolean): Promise<void> => {
  localStorage.setItem("ga-authenticate", `${bool}`)

  return Promise.resolve()
}

export const getterLanguage = (): string => {
  return localStorage.getItem("ga-language") || ""
}

export const setterLanguage = (token: string): Promise<void> => {
  localStorage.setItem("ga-language", token)

  return Promise.resolve()
}

export const getterDarkMode = (): boolean => {
  return localStorage.getItem("ga-darkmode") === null ? true : localStorage.getItem("ga-darkmode") === "true"
}

export const setterDarkMode = (bool: boolean): Promise<void> => {
  localStorage.setItem("ga-darkmode", `${bool}`)

  return Promise.resolve()
}
