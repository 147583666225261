import ModalConfirmAction from "components/modals/ModalConfirmAction"
import Table from "components/tables/Table"
import { TableDataType } from "constants/eums"
import { TableColumnsInterface } from "constants/interfaces"
import { useIsMounted } from "hooks/useIsMounted"
import { useMemo, useState } from "react"
import { toast } from "react-toastify"
import { BonusStatus } from "services/user/type"
import userService from "services/user/user.service"
import { Maybe } from "types/common"

export const columns: TableColumnsInterface[] = [
  {
    value: "id",
    title: "Id",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "bonus_id",
    title: "Bonus ID",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "expires_at",
    title: "Expires At",
    format: TableDataType.DATE,
    sorting: true
  },
  {
    value: "status",
    title: "Status",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "deposit_amount",
    title: "Deposit Amount",
    format: TableDataType.FIAT_CURRENCY,
    sorting: true
  },
  {
    value: "bonus.bonus_percent",
    title: "Bonus %",
    format: TableDataType.PERCENTAGE,
    sorting: true
  },
  {
    value: "bonus_amount",
    title: "Bonus Amount",
    format: TableDataType.FIAT_CURRENCY,
    sorting: true
  },
  {
    value: "wager_requirements",
    title: "Wager Requirements",
    format: TableDataType.NUMBER,
    sorting: true
  },
  {
    value: "wagered_amount",
    title: "Wagered Amount",
    format: TableDataType.FIAT_CURRENCY,
    sorting: true
  },
  {
    value: "transferred_amount",
    title: "Transferred Amount",
    format: TableDataType.TFIAT_CURRENCY,
    sorting: true
  },
  {
    value: "cancelled_amount",
    title: "Cancelled Amount",
    format: TableDataType.TFIAT_CURRENCY,
    sorting: true
  },
  {
    value: "created_at",
    title: "Created At",
    format: TableDataType.DATE,
    sorting: true
  },
  {
    value: "updated_at",
    title: "Updated At",
    format: TableDataType.DATE,
    sorting: true
  }
]

type UserBonusContentProps = {
  userId: string
  onSuccessCancel: () => void
  filters: { createdAt?: (Date | null)[] }
}
export const UserBonusContent = ({ userId, onSuccessCancel, filters }: UserBonusContentProps) => {
  // const [editRow, setEditRow] = useState<any>(null)
  const isMounted = useIsMounted()
  const [updatedAt, setUpdatedAt] = useState<number>(Date.now())
  const [cancelRow, setCancelRow] = useState<Maybe<Record<string, any>>>(null)
  const actions = useMemo(
    () => [
      {
        name: "Cancel",
        condition: (row: any) => row?.status === BonusStatus.ACTIVE,
        handler: setCancelRow
      }
    ],
    []
  )

  const handleCancelBonus = async (row: any): Promise<void> => {
    if (!row?.id || !userId) return

    try {
      await userService.cancelUserBonus(row.id, userId)
      toast.success("Bonus cancelled")
      onSuccessCancel()
      if (isMounted()) {
        setUpdatedAt(Date.now())
      }
    } catch (err: any) {
      toast.error(err?.data?.message ?? err?.message ?? "Failed")
    }
  }

  return (
    <>
      <Table
        title="User Bonuses"
        handler={userService.getUserBonuses}
        columns={columns}
        id={userId}
        actions={actions}
        updatedAt={updatedAt}
        filters={filters}
        // buttonHander={() => {}}
        // buttonText="Claim a Bonus"
      />

      <ModalConfirmAction
        data={cancelRow}
        handler={() => setCancelRow(null)}
        text={`Are you sure you want to cancel the bonus with an ID of ${cancelRow?.id}?`}
        actionText="Cancel a Bonus"
        actionHandler={handleCancelBonus}
      />
    </>
  )
}
