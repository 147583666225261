import React, { FC } from "react"

interface IProps {
  text: string
  handler?: any
  className?: string
}

const NavButton: FC<IProps> = (props) => {
  const { text, handler, className } = props
  return (
    <button
      className={
        `text-md font-bold px-4 py-2 bg-purple rounded-md flex items-center justify-center text-black dark:text-white ` + className
      }
      onClick={() => handler()}
    >
      {text}
    </button>
  )
}
export default NavButton
