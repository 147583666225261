import { TableDataType } from "./eums"
import { TableColumnsInterface } from "./interfaces"

export const tableActivityColumns: TableColumnsInterface[] = [
  {
    value: "user_id",
    title: "User Id",
    format: TableDataType.NUMBER,
    sorting: false
  },
  {
    value: "username",
    title: "Username",
    format: TableDataType.STRING,
    sorting: false
  },
  {
    value: "resource_type",
    title: "Resource type",
    format: TableDataType.NUMBER,
    sorting: false
  },
  {
    value: "resource_id",
    title: "Resource ID",
    format: TableDataType.NUMBER,
    sorting: false
  },
  {
    value: "action",
    title: "Action",
    format: TableDataType.STRING,
    sorting: false
  },
  {
    value: "data",
    title: "Resource data",
    format: TableDataType.STRING,
    sorting: false
  },
  {
    value: "created_at",
    title: "Created At",
    format: TableDataType.DATE,
    sorting: true
  },
]
