import { useState } from "react"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import Table from "../components/tables/Table"
import ModalConfirmAction from "../components/modals/ModalConfirmAction"
import clsx from "clsx"
import { tableKYCFilesColumns } from "constants/kyc-files"
import SingleOptTimeRangePicker from "components/date/SingleOptTimePeriodPicker"
import KYCService from "services/kyc/kyc.service"
import ModalKYCFiles from "components/modals/ModalKYCFiles"
import { ModalDeclineAction } from "components/modals/ModalDeclineAction"
import { useProps } from "contexts/PropsContext"

enum Tabs {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  DECLINED = "DECLINED"
}

export const KYCFiles = () => {
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.PENDING)
  const { t }: { t: any } = useTranslation(["landing"], { useSuspense: false })
  const [editRow, setEditRow] = useState(null)
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [declineRow, setDeclineRow] = useState<any>(null)
  const [approveRow, setApproveRow] = useState<any>(null)
  const [tableUpdatedAt, setTableUpdatedAt] = useState<Date | number>(Date.now())
  const [timeframe, setTimeframe] = useState<(Date | null)[]>([])
  const { onKYCFilesCount } = useProps()

  const handleChangeTimeFrame = (dates: (Date | null)[]) => setTimeframe(dates)

  const editRowHandler = (): void => {
    setEditRow(null)
    setOpenEditModal(false)
  }

  const onOpenApproveRow = (row: any): void => {
    setApproveRow(row)
  }

  const approveRowHandler = (): void => {
    setApproveRow(null)
    setTableUpdatedAt(Date.now())
  }

  const onApprove = async (tx: any): Promise<void> => {
    if (!tx?.id) return

    try {
      const response = await KYCService.updateKYCFilesStatus(tx.id, Tabs.COMPLETED)
      if (response) {
        // @ts-ignore
        onKYCFilesCount()
        toast.success(t("success_action_kyc_files", { actionText: "Approved" }))
      }
    } catch (err: any) {
      toast.error(err?.message || "Failed")
    }
  }

  const onOpenDeclineRow = (row: any): void => {
    setDeclineRow(row)
  }

  const declineRowHandler = (): void => {
    setDeclineRow(null)
    setTableUpdatedAt(Date.now())
  }

  const onDecline = async (rowId: string, reason: string): Promise<void> => {
    const response = await KYCService.updateKYCFilesStatus(rowId, Tabs.DECLINED, reason)
    if (response) {
      // @ts-ignore
      onKYCFilesCount()
      toast.success(t("success_action_kyc_files", { actionText: t("declined") }))
    }
  }

  const actions = [
    {
      name: t("decline"),
      condition: (row: any) => [Tabs.PENDING].includes(row?.status),
      handler: onOpenDeclineRow,
      buttonColor: "btn-secondary"
    },
    {
      name: "Approve",
      condition: (row: any) => [Tabs.PENDING].includes(row?.status),
      handler: onOpenApproveRow
    }
  ]

  const onRowClick = (row: any): void => {
    setEditRow(row)
    setOpenEditModal(true)
  }

  return (
    <div className="w-full p-8">
      <div className="w-full grid grid-col-1 gap-8 px-8">
        <div className="flex items-center justify-center text-xl text-black dark:text-white">KYC Files</div>

        <div className="flex items-center justify-center">
          <div className="tabs">
            {Object.keys(Tabs).map((tab) => (
              <a
                key={`tab-${tab}`}
                onClick={() => setActiveTab(tab as Tabs)}
                className={clsx("tab tab-bordered", {
                  "tab-active": tab === activeTab
                })}
              >
                {tab}
              </a>
            ))}
          </div>
        </div>

        <div className="max-w-md">
          <SingleOptTimeRangePicker onTimeframeChange={handleChangeTimeFrame} />
        </div>

        <Table
          title={`${activeTab} KYC Files`}
          handler={KYCService.getKYCFiles}
          columns={tableKYCFilesColumns}
          actions={actions}
          updatedAt={tableUpdatedAt}
          filters={{
            status: activeTab,
            createdAt: timeframe
          }}
          onRowClick={onRowClick}
        />

        <ModalConfirmAction
          data={approveRow}
          handler={approveRowHandler}
          text={t("modal_conformation_kyc_files", {
            actionText: "approve",
            user: approveRow?.user.username
          })}
          actionText="Approve"
          actionHandler={onApprove}
        />
        <ModalDeclineAction
          data={declineRow}
          handler={declineRowHandler}
          text={t("modal_conformation_kyc_files", {
            actionText: "decline",
            user: declineRow?.user.username
          })}
          actionText={t("decline")}
          actionHandler={onDecline}
        />
        <ModalKYCFiles
          open={openEditModal}
          data={editRow}
          handler={editRowHandler}
          approveHandler={onOpenApproveRow}
          declineHandler={onOpenDeclineRow}
        />
      </div>
    </div>
  )
}
