import { FC, useEffect } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import Modal from "./Modal"
import ImagePreview from "components/preview"
import FileUpload from "components/inputs/FileUpload"
import { enumTenant, tenantOptions } from "constants/bonus"
import { enumCMSTypes } from "constants/cms"
import InputField from "components/inputs/InputField"
import DropdownMenu from "components/inputs/DropdownMenu"
import TitledCheckbox from "components/inputs/TitledCheckbox"

interface FormValuesType {
  id: string
  active: boolean
  tenant_id: string
  banner_image: string
  name: string
  headline: string
  subheadline: string
  link: string
  order: number
}

interface IProps {
  data?: any
  open: boolean
  handler: any
  onSubmit: (values: any) => void
  type: enumCMSTypes
}

const validationSchema = Yup.object().shape({
  tenant_id: Yup.mixed().oneOf([enumTenant.Slotwhales, enumTenant.Kingcasi, enumTenant.Blockbets]).required(),
  name: Yup.string().required("This field is required"),
  banner_image: Yup.string().required("This field is required"),
})

const ModalCMS: FC<IProps> = (props) => {
  const { data, open, handler, onSubmit, type } = props

  useEffect(() => {
    formik.resetForm()
  }, [open])

  const formik = useFormik<FormValuesType>({
    initialValues: {
      id: data?.id ?? "",
      active: data?.active ?? false,
      tenant_id: data?.tenant_id ?? enumTenant.Slotwhales,
      name: data?.name ?? "",
      banner_image: data?.banner_image ?? "",
      headline: data?.headline ?? "",
      subheadline: data?.subheadline ?? "",
      link: data?.link ?? "",
      order: data?.order ?? 1,
    },
    enableReinitialize: true,
    onSubmit: async (values, formikHelpers): Promise<void> => {
      formikHelpers.setTouched({})
      await onSubmit({ ...values, type })
      handler()
    },
    validationSchema
  })

  return (
    <Modal open={open} handler={handler}>
      <div className="relative flex flex-col justify-between modal-box">
        <div className="flex flex-row space-x-4 mt-3">
          <ImagePreview 
            id="banner_image"
            value={formik.values.banner_image}
            setFieldValue={formik.setFieldValue}
          />

          <FileUpload
            id="banner_image"
            title="Upload Banner"
            setFieldValue={formik.setFieldValue}
            invalid={Boolean(formik.touched.banner_image && formik.errors.banner_image)}
            altLabelTwo={formik.touched.banner_image ? formik.errors.banner_image : ""}
          />
        </div>
        <div className="w-full">
          <InputField
            id="name"
            title="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            invalid={!!(formik.touched.name && formik.errors.name)}
            altLabelTwo={formik.touched.name ? formik.errors.name : ""}
          />
        </div>
        <div className="w-full">
          <DropdownMenu
            id="tenant_id"
            title="Tenant"
            options={tenantOptions}
            value={formik.values.tenant_id}
            onChange={formik.handleChange}
            placeholder="Please choose an option"
            invalid={Boolean(formik.touched.tenant_id && formik.errors.tenant_id)}
            altLabelOne={formik.touched.tenant_id ? formik.errors.tenant_id : ""}
          />
        </div>
        <div className="w-full">
          <TitledCheckbox id="active" title="Active" checked={formik.values.active} onChange={formik.handleChange} />
        </div>
        {
          type === enumCMSTypes.PROMOTION_BANNER ? (
            <>
              <div className="w-full">
                <InputField
                  id="headline"
                  title="Headline"
                  value={formik.values.headline}
                  onChange={formik.handleChange}
                  invalid={!!(formik.touched.headline && formik.errors.headline)}
                  altLabelTwo={formik.touched.headline ? formik.errors.headline : ""}
                />
              </div>
              <div className="w-full">
                <InputField
                  id="subheadline"
                  title="Subheadline"
                  value={formik.values.subheadline}
                  onChange={formik.handleChange}
                  invalid={!!(formik.touched.subheadline && formik.errors.subheadline)}
                  altLabelTwo={formik.touched.subheadline ? formik.errors.subheadline : ""}
                />
              </div>
              <div className="w-full">
                <InputField
                  id="link"
                  title="Link"
                  value={formik.values.link}
                  onChange={formik.handleChange}
                  invalid={!!(formik.touched.link && formik.errors.link)}
                  altLabelTwo={formik.touched.link ? formik.errors.link : ""}
                />
              </div>
            </>
          ) : type === enumCMSTypes.HOMEPAGE_BANNER ? (
            <div className="w-full">
              <InputField
                id="order"
                title="Order"
                value={formik.values.order}
                onChange={formik.handleChange}
                invalid={!!(formik.touched.order && formik.errors.order)}
                altLabelTwo={formik.touched.order ? formik.errors.order : ""}
                type="number"
              />
            </div>
          ) : null
        }
        <div className="modal-action">
          <button className="btn btn-secondary" onClick={handler}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={formik.submitForm}>
            Save
          </button>
        </div>
      </div>
    </Modal>
  )
};

export default ModalCMS;
