import { FC } from "react"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import { formatFiatCurrency } from "util/formatter"

type StatCardProps = {
  title: string
  bgColor?: string
  value: number
  loading?: boolean
  type?: string
}

export const StatCard: FC<StatCardProps> = ({ title, value, loading, bgColor, type }) => {
  const { t } = useTranslation("landing")

  return (
    <div className={`card ${bgColor || "bg-accent"} text-primary-content`}>
      <div className="card-body p-4">
        <h3 className="text-sm">{t(title)}</h3>
        <p className={clsx("font-bold	text-lg", { "loading loading-spinner loading-xs inline-flex": loading })}>
          {!loading ? (type === "currency" ? formatFiatCurrency(value, "$0") : value) : ""}
        </p>
      </div>
    </div>
  )
}
