import Table from "components/tables/Table"
import { TableDataType } from "constants/eums"
import { TableColumnsInterface } from "constants/interfaces"
import userService from "services/user/user.service"

export const columns: TableColumnsInterface[] = [
  {
    value: "old_value",
    title: "Old Value",
    format: TableDataType.STRING,
    sorting: false
  },
  {
    value: "new_value",
    title: "New Value",
    format: TableDataType.STRING,
    sorting: false
  },
  {
    value: "description",
    title: "Description",
    format: TableDataType.STRING,
    sorting: false
  },
  {
    value: "updated_by",
    title: "Updated By",
    format: TableDataType.STRING,
    sorting: false
  },
  {
    value: "created_at",
    title: "Created At",
    format: TableDataType.DATE,
    sorting: true
  }
]

type UserAccountActivityContentProps = {
  userId: string
  filters: { createdAt?: (Date | null)[] }
}
export const UserAccountActivityContent = ({ userId, filters }: UserAccountActivityContentProps) => (
  <Table title="Activity Logs" handler={userService.getAcitivtyLogs} filters={filters} columns={columns} id={userId} />
)
