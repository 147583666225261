import { ChangeEvent, FC, useEffect, useState } from "react";
import clsx from "clsx";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";

interface IProps {
  id?: string
  title?: string
  invalid?: boolean
  setFieldValue?: any
  placeholder?: string
  disabled?: boolean
  name?: string
  altLabelOne?: string
  altLabelTwo?: string
  altLabelThree?: string
}

const FileUpload: FC<IProps> = (props) => {
  const { 
    id, 
    title, 
    invalid, 
    setFieldValue, 
    placeholder, 
    disabled, 
    name, 
    altLabelOne, 
    altLabelTwo, 
    altLabelThree 
  } = props;
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue('');
  }, [id]);

  const handleUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const S3_BUCKET_NAME = process.env.REACT_APP_AWS_S3_BUCKET_NAME;
    const REGION = process.env.REACT_APP_AWS_REGION;
    if (e.currentTarget.files && S3_BUCKET_NAME) {
      try {
        setLoading(true);
        const file = e.currentTarget.files[0];

        AWS.config.update({
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        });

        const s3 = new AWS.S3({ region: REGION, params: { bucket: S3_BUCKET_NAME } });

        const extension = file.name.split('.').pop();
        const fileName = `${uuidv4()}.${extension}`;

        const params = {
          Bucket: S3_BUCKET_NAME,
          Key: fileName,
          Body: file
        };

        const upload = s3.putObject(params).on('httpUploadProgress', (e) => {
          if (e.loaded === e.total) {
            setLoading(false);
          }
        }).promise();

        upload.then(() => {
          const uploadedURL = `https://${S3_BUCKET_NAME}.s3.amazonaws.com/${fileName}`;
          setFieldValue(id, uploadedURL)
        });
      } catch (err) {
        console.error('Debug handleUploadFile', err);
      }
    }
  };

  return (
    <div className="w-full form-control">
      {Boolean(title) && (
        <div className="flex w-full justify-between items-center">
          <label className="label flex w-full justify-between">
            <span className={clsx("label-text", { "text-error": invalid })}>{title}</span>
            <span className={`label-text-alt ${invalid ? "text-error" : ""}`}>{altLabelOne}</span>
          </label>
          {
            loading && (
              <div className="loading loading-spinner loading-xs inline-flex"></div>
            )
          }
        </div>
      )}
      <input
        type="file"
        value={value}
        onChange={handleUploadFile}
        placeholder={placeholder}
        className={`w-full file-input file-input-bordered ${invalid ? "input-error" : ""}`}
        disabled={disabled}
        accept="image/*"
        {...(id && { id })}
        {...(name && { name })}
      />
      <label>
        <span className={`label-text-alt ${invalid ? "text-error" : ""}`}>{altLabelTwo}</span>
        <span className={`label-text-alt ${invalid ? "text-error" : ""}`}>{altLabelThree}</span>
      </label>
    </div>
  );
};

export default FileUpload;
