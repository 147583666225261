import { ChangeEvent, useState } from "react"
import { toast } from "react-toastify"
import InputField from "components/inputs/InputField"
import userService from "services/user/user.service"

const CommentsCardAddInput = ({ userId, category, onSuccess }: { userId: string; category: string; onSuccess: () => void }) => {
  const [value, setValue] = useState("")
  const [loading, setLoading] = useState(false)

  const handleChangeComment = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const handleAddComment = async () => {
    if (!value || loading) return
    try {
      setLoading(true)
      await userService.addComment(userId, value, category)
      setValue("")
      toast.success("Added a comment")
      onSuccess()
    } catch (error: any) {
      toast.error(error?.message ?? "Failed to add a comment")
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex items-center gap-2 pb-10">
      <InputField value={value} onChange={handleChangeComment} disabled={loading} />
      <button className="btn btn-primary mt-4" onClick={handleAddComment}>
        Add a comment
      </button>
    </div>
  )
}

export default CommentsCardAddInput
