import { FC, useState } from "react"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import Table from "components/tables/Table"
import { URL } from "constants/api/requests"
import { formatFiatCurrency } from "util/formatter"
import DropdownMenu from "components/inputs/DropdownMenu"
import { tableTransactionsColumns } from "constants/transactions"
import ModalConfirmAction from "components/modals/ModalConfirmAction"
import { transactionStatuses, transactionTypes } from "constants/data"
import transactionService from "services/transaction/transaction.service"
import { ModalDeclineAction } from "components/modals/ModalDeclineAction"
import SingleOptTimeRangePicker from "components/date/SingleOptTimePeriodPicker"

const Transactions: FC = () => {
  // const navigate = useNavigate()
  const { t }: { t: any } = useTranslation(["landing"], { useSuspense: false })

  const [type, setType] = useState<string>("")
  const [status, setStatus] = useState<string>("")
  
  const [confirmRow, setConfirmRow] = useState<any>(null)
  const [declineRow, setDeclineRow] = useState<any>(null)

  const [declinePendingCryptoRow, setDelinePendingCryptoRow] = useState<any>(null)
  const [tableUpdatedAt, setTableUpdatedAt] = useState<Date | number>(Date.now())
  const [timeframe, setTimeframe] = useState<(Date | null)[]>([])

  const handleChangeTimeFrame = (dates: (Date | null)[]) => setTimeframe(dates)

  const onRowClick = (row: any): void => {
    if (row?.user_id) {
      // navigate(URL.USER.replace(":id", row?.user_id))
      window.open(URL.USER.replace(":id", row?.user_id), "_blank")
    }
  }

  const onOpenConfirmRow = (row: any): void => {
    setConfirmRow(row)
  }

  const confirmRowHandler = (): void => {
    setConfirmRow(null)
    setTableUpdatedAt(Date.now())
  }

  const onConfirmTransaction = async (): Promise<void> => {
    const response = await transactionService.confirmTransaction(confirmRow?.id)
    if (response) {
      // @ts-ignore
      toast.success(t("success_action_transaction", { actionText: t("confirmed") }))
    }
  }

  const onOpenDeclineRow = (row: any): void => {
    setDeclineRow(row)
  }

  const onOpenDeclinePendingCryptoRow = (row: any): void => {
    setDelinePendingCryptoRow(row)
  }

  const declineRowHandler = (): void => {
    setDeclineRow(null)
    setTableUpdatedAt(Date.now())
  }

  const declinePendingCryptoRowHandler = (): void => {
    setDelinePendingCryptoRow(null)
    setTableUpdatedAt(Date.now())
  }

  const onDeclineTransaction = async (): Promise<void> => {
    const response = await transactionService.declineTransaction(declineRow?.id)
    if (response) {
      // @ts-ignore
      toast.success(t("success_action_transaction", { actionText: t("declined") }))
    }
  }

  const onDeclinePendingCryptoTransaction = async (rowId: string, reason: string): Promise<void> => {
    const response = await transactionService.declineTransaction(rowId, reason)
    if (response) {
      // @ts-ignore
      toast.success(t("success_action_transaction", { actionText: t("declined") }))
    }
  }

  const actions = [
    {
      name: t("decline"),
      condition: (row: any) => row?.status === "UNCONFIRMED" && row?.type === "WITHDRAWAL",
      handler: onOpenDeclineRow,
      buttonColor: "btn-secondary"
    },
    {
      name: t("confirm"),
      condition: (row: any) => row?.status === "UNCONFIRMED" && row?.type === "WITHDRAWAL",
      handler: onOpenConfirmRow
    },
    {
      name: t("decline"),
      condition: (row: any) => row?.status === "PENDING" && row?.type === "WITHDRAWAL" && row?.coin !== "USD",
      handler: onOpenDeclinePendingCryptoRow,
      buttonColor: "btn-secondary"
    },
  ]

  return (
    <div className="grid w-full gap-8 p-8 grid-col-1">
      <div className="flex gap-4">
        <div className="flex flex-row flex-1 max-w-md space-x-4">
          <DropdownMenu title="Transaction Type" options={transactionTypes} value={type} onChange={(e: any) => setType(e.target.value)} />
          <DropdownMenu
            title="Transaction Status"
            options={transactionStatuses}
            value={status}
            onChange={(e: any) => setStatus(e.target.value)}
          />
        </div>
        <div className="max-w-md">
          <SingleOptTimeRangePicker onTimeframeChange={handleChangeTimeFrame} />
        </div>
      </div>
      <Table
        title={t("transactions")}
        handler={transactionService.getTransactionsTable}
        columns={tableTransactionsColumns}
        actions={actions}
        updatedAt={tableUpdatedAt}
        filters={{ type, status, createdAt: timeframe }}
        onRowClick={onRowClick}
      />
      <ModalConfirmAction
        data={confirmRow}
        handler={confirmRowHandler}
        text={t("modal_conformation_transaction", {
          actionText: t("confirm"),
          usd_amount: formatFiatCurrency(confirmRow?.usd_amount),
          user: confirmRow?.user
        })}
        actionText={t("confirm")}
        actionHandler={onConfirmTransaction}
      />
      <ModalConfirmAction
        data={declineRow}
        handler={declineRowHandler}
        text={t("modal_conformation_transaction", {
          actionText: t("decline"),
          usd_amount: formatFiatCurrency(declineRow?.usd_amount),
          user: declineRow?.user
        })}
        actionText={t("decline")}
        actionHandler={onDeclineTransaction}
      />
      <ModalDeclineAction
        data={declinePendingCryptoRow}
        handler={declinePendingCryptoRowHandler}
        text={t("modal_conformation_transaction", {
          actionText: "decline",
          usd_amount: formatFiatCurrency(declinePendingCryptoRow?.usd_amount),
          user: declinePendingCryptoRow?.user
        })}
        actionText={t("decline")}
        actionHandler={onDeclinePendingCryptoTransaction}
      />
    </div>
  )
}

export default Transactions
