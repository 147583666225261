import React, { FC } from "react"
import { MdKeyboardArrowDown } from "react-icons/md"

interface IProps {
  value: any
  handler?: any
  className?: string
}

const DropdownButton: FC<IProps> = (props) => {
  const { value, handler, className } = props
  return (
    <div className="p-2 rounded-md bg-grey-light-2 dark:bg-grey-med-2 text-grey-light hover:text-black dark:hover:text-white">
      <button className={`text-md font-bold flex items-center justify-center  ` + className} onClick={() => handler()}>
        {value}
        <MdKeyboardArrowDown className="w-6 h-6" />
      </button>
    </div>
  )
}
export default DropdownButton
