import React, { FC, useState } from "react"
import DropdownButton from "../buttons/DropdownButton"

interface IProps {
  value: number
  onValueChange: any
  columns: any[]
}

const TableDropdown: FC<IProps> = (props) => {
  const { value, onValueChange, columns } = props
  const [open, setOpen] = useState<boolean>(false)

  const handleValueChange = (column: any): void => {
    onValueChange(column)
    setOpen(false)
  }

  return (
    <div className="relative">
      <DropdownButton value={value} handler={() => setOpen(!open)} />
      {open ? (
        <div className="mt-2 absolute bg-grey-light-2 dark:bg-grey-med-2 z-20">
          <ul className="rounded-md text-md text-black dark:text-white">
            {columns.map((column, key) => (
              <li key={key} onClick={() => handleValueChange(column)} className="px-4 py-2 hover:text-grey-light cursor-pointer">
                {column}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default TableDropdown
