import React from "react"
import { Navigate, Outlet } from "react-router-dom"

import { useProps } from "../contexts/PropsContext"

const AuthenticatedRoutes = () => {
  const { authenticated } = useProps()

  return authenticated ? <Outlet /> : <Navigate to="/" />
}

export default AuthenticatedRoutes
