import { REQ } from "constants/api/requests"
import { IAuth, IAuthService, LoginResponse, LogoutResponse } from "./type"
import useApi, { cookies, setCookies } from "util/api"
import { IResponsePayload } from "constants/interfaces"
import { enumTenant } from "constants/bonus"

class AuthService implements IAuthService {
  public async signIn(body: IAuth): Promise<void> {
    await useApi.post<IResponsePayload<LoginResponse>>(REQ.AUTH.LOGIN, { ...body, tenantId: enumTenant.Blockbets })
  }

  public async refreshToken(token: string) {
    const { data } = await useApi.get(REQ.AUTH.REFRESH, {
      headers: {
        Authorization: `Bearer ${token}`,
        _retry: true
      }
    })

    return data
  }

  public async logout(): Promise<void> {
    await useApi.get<IResponsePayload<LogoutResponse>>(REQ.AUTH.LOGOUT)
  }

  public registerAccessToken(token: string | string[]): void {
    if (typeof token === "string") {
      setCookies("tokens", { ...cookies, access_token: token })
    }
  }

  public registerRefreshToken(token: string | string[]): void {
    if (typeof token === "string") {
      setCookies("tokens", { ...cookies, refresh_token: token })
    }
  }
}

export default new AuthService()
