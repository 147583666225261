import useApi from "util/api"
import { REQ } from "constants/api/requests"
import { StateResponse, TransactionFilterType, FilteredTransacation, Transaction, ITotalDataService, StatsResponse } from "./types"
import { IResponse } from "constants/interfaces"

class TotalDataService implements ITotalDataService {
  public async getAllTransactions(): Promise<Transaction[]> {
    const { data } = await useApi.get<IResponse<Transaction[]>>(REQ.ADMIN_DASHBOARD.TRANSACTIONS.GET_ALL)

    if (data?.success) {
      return data?.response
    }

    return []
  }

  public async getTransactionsTable(
    page: number,
    pageSize: number,
    id: number,
    filter: TransactionFilterType,
    sortRow?: string,
    sortOrder?: string
  ): Promise<FilteredTransacation | []> {
    const params = {
      page,
      pageSize,
      type: filter?.type || null,
      status: filter?.status || null,
      sortRow,
      sortOrder
    }

    const { data } = await useApi.get<IResponse<FilteredTransacation>>(REQ.ADMIN_DASHBOARD.TRANSACTIONS.GET, { params: { ...params } })

    if (data?.success) {
      return data?.response
    }

    return []
  }

  public async getUserCount(dates: (Date | null)[]): Promise<StateResponse | undefined> {
    const params = {
      startDate: dates?.[0] || null,
      endDate: dates?.[1] || null
    }

    const { data } = await useApi.get<IResponse<StateResponse>>(REQ.ADMIN_DASHBOARD.USERS_COUNT, { params })

    if (data?.success) {
      return data?.response
    }
  }

  public async getTotalDeposit(dates: (Date | null)[]): Promise<StateResponse | undefined> {
    const params = {
      startDate: dates?.[0] || null,
      endDate: dates?.[1] || null
    }

    const { data } = await useApi.get<IResponse<StateResponse>>(REQ.ADMIN_DASHBOARD.TRANSACTIONS.DEPOSIT_TOTAL, { params })

    if (data?.success) {
      return data?.response
    }
  }

  public async getTotalWithdrawal(dates: (Date | null)[]): Promise<StateResponse | undefined> {
    const params = {
      startDate: dates?.[0] || null,
      endDate: dates?.[1] || null
    }

    const { data } = await useApi.get<IResponse<StateResponse>>(REQ.ADMIN_DASHBOARD.TRANSACTIONS.WITHDRAWAL_TOTAL, {
      params
    })

    if (data?.success) {
      return data?.response
    }
  }

  public async getTotalPendingWithdrawal(dates: (Date | null)[]): Promise<StateResponse | undefined> {
    const params = {
      startDate: dates?.[0] || null,
      endDate: dates?.[1] || null
    }

    const { data } = await useApi.get<IResponse<StateResponse>>(REQ.ADMIN_DASHBOARD.TRANSACTIONS.WITHDRAWAL_PENDING, {
      params
    })

    if (data?.success) {
      return data?.response
    }
  }

  public async getTotalWager(dates: (Date | null)[]): Promise<StateResponse | undefined> {
    const params = {
      startDate: dates?.[0] || null,
      endDate: dates?.[1] || null
    }

    const { data } = await useApi.get<IResponse<StateResponse>>(REQ.ADMIN_DASHBOARD.TRANSACTIONS.BETS_TOTAL, { params })

    if (data?.success) {
      return data?.response
    }
  }

  public async getTotalWins(dates: (Date | null)[]): Promise<StateResponse | undefined> {
    const params = {
      startDate: dates?.[0] || null,
      endDate: dates?.[1] || null
    }

    const { data } = await useApi.get<IResponse<StateResponse>>(REQ.ADMIN_DASHBOARD.TRANSACTIONS.WINS_TOTAL, { params })

    if (data?.success) {
      return data?.response
    }
  }

  public async getTotalRakeback(dates: (Date | null)[]): Promise<StateResponse | undefined> {
    const params = {
      startDate: dates?.[0] || null,
      endDate: dates?.[1] || null
    }

    const { data } = await useApi.get<IResponse<StateResponse>>(REQ.ADMIN_DASHBOARD.TRANSACTIONS.RAKEBACK_TOTAL, { params })

    if (data?.success) {
      return data?.response
    }
  }

  public async getTotalAffiliate(dates: (Date | null)[]): Promise<StateResponse | undefined> {
    const params = {
      startDate: dates?.[0] || null,
      endDate: dates?.[1] || null
    }

    const { data } = await useApi.get<IResponse<StateResponse>>(REQ.ADMIN_DASHBOARD.TRANSACTIONS.AFFILIATE_TOTAL, { params })

    if (data?.success) {
      return data?.response
    }
  }

  public async getTotalPromoCode(dates: (Date | null)[]): Promise<StateResponse | undefined> {
    const params = {
      startDate: dates?.[0] || null,
      endDate: dates?.[1] || null
    }

    const { data } = await useApi.get<IResponse<StateResponse>>(REQ.ADMIN_DASHBOARD.TRANSACTIONS.PROMOCODE_TOTAL, { params })

    if (data?.success) {
      return data?.response
    }
  }

  public async getStats(dates: (Date | null)[]): Promise<StatsResponse | undefined> {
    const params = {
      startDate: dates?.[0] || null,
      endDate: dates?.[1] || null
    }

    const { data } = await useApi.get<IResponse<StatsResponse>>(REQ.ADMIN_DASHBOARD.STATS, { params })

    if (data?.success) {
      return data?.response
    }
  }
}

export default new TotalDataService()
