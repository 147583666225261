import { TableDataType } from "./eums"
import { TableColumnsInterface } from "./interfaces"

export const tableTransactionsColumns: TableColumnsInterface[] = [
  {
    value: "id",
    title: "Id",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "user_id",
    title: "User_Id",
    format: TableDataType.STRING,
    // link: (id: string) => `/user/${id}`,
    sorting: false
  },
  {
    value: "user",
    title: "User",
    format: TableDataType.STRING,
    sorting: false
  },
  {
    value: "amount",
    title: "Crypto Amount",
    format: TableDataType.CRYPTO_CURRENCY,
    sorting: false
  },
  {
    value: "usd_amount",
    title: "USD Amount",
    format: TableDataType.FIAT_CURRENCY,
    sorting: true
  },
  {
    value: "status",
    title: "Transaction Status",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "coin",
    title: "Crypto Coin",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "withdrawal_address",
    title: "Wallet Address",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "type",
    title: "Type",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "payout_id",
    title: "Payout Id",
    format: TableDataType.STRING,
    sorting: false
  },
  {
    value: "cardNumber",
    title: "Card Number",
    format: TableDataType.STRING,
    sorting: false
  },
  {
    value: "created_at",
    title: "Created At",
    format: TableDataType.DATE,
    sorting: true
  },
  {
    value: "updated_at",
    title: "Updated At",
    format: TableDataType.DATE,
    sorting: true
  }
]

export const tableBankTransfersColumns: TableColumnsInterface[] = [
  {
    value: "id",
    title: "Id",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "user_id",
    title: "User_Id",
    format: TableDataType.STRING,
    sorting: false
  },
  {
    value: "usd_amount",
    title: "USD Amount",
    format: TableDataType.FIAT_CURRENCY,
    sorting: true
  },
  {
    value: "other.receiverName",
    title: "Receiver Name",
    format: TableDataType.STRING,
    sorting: false
  },
  {
    value: "other.iban",
    title: "IBAN",
    format: TableDataType.STRING,
    sorting: false
  },
  {
    value: "other.bic",
    title: "BIC",
    format: TableDataType.STRING,
    sorting: false
  },
  {
    value: "other.bankName",
    title: "Bank Name",
    format: TableDataType.STRING,
    sorting: false
  },
  {
    value: "other.bankAddress",
    title: "Bank Address",
    format: TableDataType.STRING,
    sorting: false
  },
  {
    value: "other.reason",
    title: "Decline Reason",
    format: TableDataType.STRING,
    sorting: false
  },
  {
    value: "created_at",
    title: "Created At",
    format: TableDataType.DATE,
    sorting: true
  },
  {
    value: "updated_at",
    title: "Updated At",
    format: TableDataType.DATE,
    sorting: true
  }
]
