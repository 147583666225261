import { useCallback, useState } from "react"
import { GetSingleUser } from "services/user/type"
import { ModalEditUser } from "./ModalEditUser"

type EditUserProfileProps = {
  user: GetSingleUser
  onSuccess: () => void
}
export const EditUserProfile = ({ user, onSuccess }: EditUserProfileProps) => {
  const [open, setOpen] = useState(false)
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <>
      <button className="btn btn-primary" onClick={() => setOpen(true)}>
        Edit User
      </button>
      <ModalEditUser user={user} handler={handleClose} open={open} onSuccess={onSuccess} />
    </>
  )
}
