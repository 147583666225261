import { useState, useEffect, FC } from "react"
import { useTranslation } from "react-i18next"
import TimeRangePicker from "./TimeRangePicker"

interface IProps {
  onTimeframeChange: (timeframe: Date[]) => void
}

const SingleOptTimeRangePicker: FC<IProps> = ({ onTimeframeChange }) => {
  const { t } = useTranslation()

  const [timeframe, setTimeframe] = useState<(Date | null)[]>([null, null])

  useEffect(() => {
    if (timeframe.filter(Boolean).length === 2) {
      onTimeframeChange(timeframe as Date[])
    }
  }, [onTimeframeChange, timeframe])

  return (
    <div className="w-full block gap-2 sm:flex-row flex-col">
      <label className="label">
        <span className="label-text">{t("time_period_filter")}</span>
      </label>
      <TimeRangePicker
        timeframe={timeframe}
        setTimeframe={setTimeframe}
        onTimeframeChange={onTimeframeChange}
        showTime
        dateFormat="dd.MM.yyyy HH:mm"
      />
    </div>
  )
}

export default SingleOptTimeRangePicker
