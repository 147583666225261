import React, { FC } from "react"
import { animated, useTransition } from "@react-spring/web"

import ModalBg from "./ModalBg"

interface IProps {
  children: any
  open: boolean
  handler: any
}

const Modal: FC<IProps> = (props) => {
  const { children, open, handler } = props

  const transition = useTransition(open, {
    config: {
      mass: 1,
      tension: 600,
      friction: 40,
      clamp: true,
      precision: 0.01,
      velocity: 0
    },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  return (
    <>
      {transition((style: any, item: boolean) => {
        return item ? (
          <animated.div style={style} className="fixed top-0 left-0 z-30 flex h-screen w-screen min-w-[300px] items-center">
            <div className="flex w-full justify-center overflow-y-auto">
              <ModalBg handler={handler} />
              <div className="relative z-20 max-w-full">{children}</div>
            </div>
          </animated.div>
        ) : (
          <></>
        )
      })}
    </>
  )
}

export default Modal
