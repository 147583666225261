import { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import Table from "components/tables/Table"
import SearchInput from "components/search/SearchInput"
import { tableActivityColumns } from "constants/activity"
import activityService from "services/activity/activity.service"
import SingleOptTimeRangePicker from "components/date/SingleOptTimePeriodPicker"

const Activity: FC = () => {
  const { t } = useTranslation(["landing"], { useSuspense: false })

  const [query, setQuery] = useState<string>("")
  const [timeframe, setTimeframe] = useState<(Date | null)[]>([])

  const handleChangeTimeFrame = (dates: (Date | null)[]) => setTimeframe(dates)

  return (
    <div className="grid w-full gap-8 p-8 grid-col-1">
      <div className="flex flex-row items-end justify-between">
        <div className="flex gap-4">
          <div className="max-w-sm">
            <SearchInput title="Username/Email" onChange={setQuery} />
          </div>
          <div className="max-w-md">
            <SingleOptTimeRangePicker onTimeframeChange={handleChangeTimeFrame} />
          </div>
        </div>
      </div>
      <Table
        title={t("activity")}
        handler={activityService.getAdminActivity}
        columns={tableActivityColumns}
        filters={{ q: query, createdAt: timeframe }}
      />
    </div>
  )
}

export default Activity
