import React, { FC } from "react"

interface IProps {
  title?: string
  value?: any
  onChange?: any
  placeholder?: string
  disabled?: boolean
  id?: string
  name?: string
  type?: string
  invalid?: boolean
  altLabelOne?: string
  altLabelTwo?: string
  altLabelThree?: string
}

const InputField: FC<IProps> = (props) => {
  const { title, value, onChange, placeholder, disabled, id, name, type, invalid, altLabelOne, altLabelTwo, altLabelThree } = props
  return (
    <div className="w-full form-control">
      <label className="label">
        <span className={`label-text ${invalid ? "text-error" : ""}`}>{title}</span>
        <span className={`label-text-alt ${invalid ? "text-error" : ""}`}>{altLabelOne}</span>
      </label>
      <input
        type={type || "text"}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`w-full input input-bordered ${invalid ? "input-error" : ""}`}
        disabled={disabled}
        {...(id && { id })}
        {...(name && { name })}
      />
      <label>
        <span className={`label-text-alt ${invalid ? "text-error" : ""}`}>{altLabelTwo}</span>
        <span className={`label-text-alt ${invalid ? "text-error" : ""}`}>{altLabelThree}</span>
      </label>
    </div>
  )
}

export default InputField
