import { ChangeEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import NavButton from "../buttons/NavButton"

type TablePaginationProps = {
  page: number
  totalPages: number
  onJump: (jumpTo: number) => void
}
const TablePagination = ({ page, totalPages, onJump }: TablePaginationProps) => {
  const { t } = useTranslation("landing", { useSuspense: false })
  const [jumpTo, setJumpTo] = useState<string | number>(page)

  useEffect(() => {
    setJumpTo(page)
  }, [page])

  return (
    <div className="w-full p-2 flex items-center justify-between">
      <div className="w-full flex items-center justify-between">
        <div className="flex items-center text-sm">
          <div className="text-sm pr-8">{t("pagination", { page: page, total: totalPages })}</div>

          <input
            type="number"
            className="input input-bordered input-sm w-[100px] !pr-1"
            min={1}
            value={jumpTo}
            onChange={(ev: ChangeEvent<HTMLInputElement>) => setJumpTo(ev.target.value)}
          />
          <button className="btn btn-sm btn-primary ml-2" onClick={() => onJump(Math.min(Number(jumpTo) || 1, totalPages))}>
            GO
          </button>
        </div>
        <div className="flex justify-center items-center gap-4">
          {page > 1 && <NavButton className="bg-grey-light-1 dark:bg-grey-light" text={t("previous")} handler={() => onJump(page - 1)} />}
          {page < totalPages && <NavButton text={t("next")} handler={() => onJump(page + 1)} />}
        </div>
      </div>
    </div>
  )
}

export default TablePagination
