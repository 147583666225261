import { useTranslation } from "react-i18next"
import Modal from "./Modal"

interface IProps {
  data: any
  handler: any
  text: string
  actionText: string
  actionHandler: any
}

const ModalConfirmAction = (props: IProps) => {
  const { t } = useTranslation("landing", { useSuspense: false })
  const { data, handler, text, actionText, actionHandler } = props

  // const { t } = useTranslation("landing", { useSuspense: false })

  const onSubmit = async (): Promise<void> => {
    await actionHandler(data)
    handler()
  }

  return (
    <Modal open={Boolean(data)} handler={handler}>
      <div className="relative flex flex-col justify-between modal-box">
        {text && <p>{text}</p>}
        <div className="modal-action">
          <button className="btn btn-secondary" onClick={handler}>
            {t("cancel")}
          </button>
          <button className="btn btn-primary" onClick={onSubmit}>
            {actionText}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalConfirmAction
