import { BonusType } from "types/api"

export enum enumTenant {
  Slotwhales = "Slotwhales",
  Kingcasi = "Kingcasi",
  Blockbets = "Blockbets"
}
export const tenantOptions = [
  // { value: enumTenant.Slotwhales, name: enumTenant.Slotwhales },
  // { value: enumTenant.Kingcasi, name: enumTenant.Kingcasi },
  { value: enumTenant.Blockbets, name: enumTenant.Blockbets }
]

export const bonusTypeOptions = Object.values(BonusType).map((value) => ({
  name: value,
  value,
  disable: value === BonusType.CUSTOM ? true : false // TODO: Remove CUSTOM bonus type
}))

export enum BonusGameCategory {
  card = "card",
  casual = "casual",
  craps = "craps",
  crash = "crash",
  fishing = "fishing",
  lottery = "lottery",
  poker = "poker",
  roulette = "roulette",
  slots = "slots",
  video_poker = "video_poker",
  virtual_sports = "virtual_sports",

  live_casino = "live_casino",
  sportsBook = "sportsBook"
}
