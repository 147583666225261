import { TableDataType } from "./eums";
import { TableColumnsInterface } from "./interfaces";

export const tableKYCFilesColumns: TableColumnsInterface[] = [
    {
      value: "id",
      title: "Id",
      format: TableDataType.STRING,
      sorting: true
    },
    {
      value: "user.username",
      title: "User",
      format: TableDataType.STRING,
      sorting: false
    },
    {
        value: "img_idcard_front",
        title: "IDCard Front",
        format: TableDataType.FILE,
        sorting: true
    },
    {
        value: "img_idcard_back",
        title: "IDCard Back",
        format: TableDataType.FILE,
        sorting: true
    },
    {
        value: "doc_proof_address",
        title: "Proof of Address",
        format: TableDataType.FILE,
        sorting: true
    },
    {
        value: "doc_add_verify",
        title: "Verification Document",
        format: TableDataType.FILE,
        sorting: true
    },
    {
        value: "status",
        title: "Status",
        format: TableDataType.STRING,
        sorting: true
    },
    {
      value: "created_at",
      title: "Created At",
      format: TableDataType.DATE,
      sorting: true
    },
    {
      value: "updated_at",
      title: "Updated At",
      format: TableDataType.DATE,
      sorting: true
    }
  ]
