import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "./css/index.css"
import App from "./App"
import "react-toastify/dist/ReactToastify.css"
import PropsProvider from "./contexts/PropsContext"
import "./i18n"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <>
    <PropsProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PropsProvider>
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      className="z-50"
    />
  </>
)
