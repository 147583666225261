import { ChangeEvent } from "react"

type InputToggleProps = {
  id: string
  title: string
  checked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}
export const InputToggle = ({ id, title, checked, onChange }: InputToggleProps) => {
  return (
    <div className="form-control w-52">
      <label className="cursor-pointer label">
        <span className="label-text">{title}</span>
        <input type="checkbox" id={id} className="toggle toggle-success" checked={checked} onChange={onChange} />
      </label>
    </div>
  )
}
