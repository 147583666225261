import React, { FC } from "react"

interface IProps {
  title: string
  checked: boolean
  onChange: any
  id?: string
}
const TitledCheckbox: FC<IProps> = (props) => {
  const { title, checked, onChange, id } = props
  return (
    <div className="form-control">
      <label className="cursor-pointer label">
        <span className="label-text">{title}</span>
        <input id={id} type="checkbox" checked={checked} onChange={onChange} className="checkbox" />
      </label>
    </div>
  )
}

export default TitledCheckbox
