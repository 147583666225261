import clsx from "clsx"
import { useState, useCallback, useEffect } from "react"
import { toast } from "react-toastify"
import { BonusStatus, UserBonus } from "services/user/type"
import userService from "services/user/user.service"
import { Maybe, Optional } from "types/common"
import { ModalAddUserBonus } from "./ModalAddUserBonus"

type AddUserBonusProps = {
  userId: string
  bonusBalance?: number
  onSuccess: () => void
}
export const AddUserBonus = ({ userId, onSuccess }: AddUserBonusProps) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <button className="btn btn-secondary" onClick={() => setOpen(true)}>
        Add Bonus
      </button>

      <ModalAddUserBonus open={open} onClose={() => setOpen(false)} userId={userId} onSuccess={onSuccess} />
    </>
  )
}
