import React, { FC } from "react"
import { useTranslation } from "react-i18next"

const NoData: FC = () => {
  const { t } = useTranslation("landing", { useSuspense: false })

  return (
    <>
      <div className="w-full rounded-md flex items-center justify-center p-4 text-lg text-black dark:text-white bg-grey-light-2 dark:bg-grey-med-2">
        {t("no_data")}
      </div>
    </>
  )
}

export default NoData
