import { toast } from "react-toastify"
import useApi from "util/api"
import { CMSActionResponse, CMSTableResponse, CMSTextActionResponse, CMSTextResponse, CreateCMSPayload, EditCMSPayload, EditCMSTextPayload, ICMSService } from "./type"
import { IResponse } from "constants/interfaces"
import { REQ } from "constants/api/requests"
import { enumCMSTypes } from "constants/cms"

export const CMSService = {
  async getCMSTable(
    page: number,
    pageSize: number,
    id: number | undefined,
    { q, type, active, tenant }: { q?: string; type?: enumCMSTypes; active?: boolean; tenant?: string },
    sortRow?: string,
    sortOrder?: string
  ): Promise<CMSTableResponse | void> {
    try {
      const params = {
        page,
        pageSize,
        q,
        type,
        tenant,
        active,
        sortRow,
        sortOrder
      }

      const { data } = await useApi.get<IResponse<CMSTableResponse>>(REQ.ADMIN_DASHBOARD.CMS.GET, { params })

      if (data?.success) {
        return data?.response
      }
    } catch (err: any) {
      toast.error(err?.message ?? "Failed to get cms banner list")
    }
  },

  async createCMS(payload: CreateCMSPayload): Promise<CMSActionResponse | void> {
    const { data } = await useApi.post<IResponse<CMSActionResponse>>(REQ.ADMIN_DASHBOARD.CMS.CREATE, payload)

    if (data?.success) {
      return data?.response
    }
  },

  async editCMS(id: string, payload: EditCMSPayload): Promise<CMSActionResponse | void> {
    const { data } = await useApi.put<IResponse<CMSActionResponse>>(
      REQ.ADMIN_DASHBOARD.CMS.UPDATE.replace(":id", id),
      payload
    )

    if (data?.success) {
      return data?.response
    }
  },

  async getCMSTextData(
    { type, active, tenant }: { type?: enumCMSTypes; active?: boolean; tenant?: string },
  ): Promise<CMSTextResponse | void> {
    try {
      const params = {
        type,
        tenant,
        active,
      }

      const { data } = await useApi.get<IResponse<CMSTextResponse>>(REQ.ADMIN_DASHBOARD.CMSText.GET, { params })

      if (data?.success) {
        return data?.response
      }
    } catch (err: any) {
      toast.error(err?.message ?? "Failed to get cms banner list")
    }
  },

  async createCMSText(payload: EditCMSTextPayload): Promise<CMSTextActionResponse | void> {
    const { data } = await useApi.post<IResponse<CMSTextActionResponse>>(
      REQ.ADMIN_DASHBOARD.CMSText.CREATE,
      payload
    )

    if (data?.success) {
      return data?.response
    }
  },

  async editCMSText(id: string, payload: EditCMSTextPayload): Promise<CMSTextActionResponse | void> {
    const { data } = await useApi.put<IResponse<CMSTextActionResponse>>(
      REQ.ADMIN_DASHBOARD.CMSText.UPDATE.replace(":id", id),
      payload
    )

    if (data?.success) {
      return data?.response
    }
  }
}
