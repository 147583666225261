import { CardGateway } from "types/api"

const snelpayTitle = "Snelpay"
const finvertTitle = "Finvert"
const gumballTitle = "Gumball"

export const cardGatewayTitles = {
  [CardGateway.SP]: `${snelpayTitle}`,
  [CardGateway.FS]: `${finvertTitle} FTD (pfx)`,
  [CardGateway.FV0]: `${finvertTitle} FTD`,

  [CardGateway.GB1]: `${gumballTitle} 1`,
  [CardGateway.GB2]: `${gumballTitle} 2`,
  [CardGateway.FV1]: `${finvertTitle} 1`,
  [CardGateway.FV2]: `${finvertTitle} 2`
}

export const GatewayOptions = Object.keys(cardGatewayTitles).map((gateway) => ({
  value: gateway,
  name: cardGatewayTitles[gateway as keyof typeof cardGatewayTitles],
  disable: [CardGateway.FS, CardGateway.FV0, CardGateway.FV1, CardGateway.FV2].includes(gateway as any) ? true : false
}))
