export enum TableDataType {
  NUMBER,
  FIAT_CURRENCY,
  TFIAT_CURRENCY,
  CRYPTO_CURRENCY,
  STRING,
  BOOLEAN,
  DATE,
  JSON,
  PERCENTAGE,
  IMAGE,
  FILE
}

export type TrustedStatusType = "UNTRUSTED" | "TRUSTED" | "INVALID" | "NOT_STARTED"

export enum SortOrderBy {
  ID = "id",
  CREATED_AT = "created_at"
}

export enum SortOrderType {
  ASC = "asc",
  DESC = "desc"
}

export enum GameStatusType {
  Play = "PLAY",
  Claim = "CLAIM",
  Claimed = "CLAIMED",
  Pending = "PENDING"
}
