type Props = {
    title: string;
    path: string;
}

const FileViewer = ({ title, path }: Props) => {
    return (
        <div className="w-full form-control">
            <label className="label">
                <span className="label-text">{title}</span>
            </label>
            {
                path ? path.match(/\.(jpg|jpeg|png|gif)$/i) ? (
                    <div className="h-20 cursor-pointer" onClick={() => window.open(path, '_blank')}>
                        <img
                            className="w-20 h-20 mx-auto"
                            src={path}
                            alt="IDCard Back"
                        />
                    </div>
                ) : (
                    <div className="cursor-pointer" onClick={() => window.open(path, '_blank')}>
                        Click here
                    </div>
                ) : null
            }
        </div>
    );
};

export default FileViewer;
