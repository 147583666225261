import React, { useState } from "react"
import { MdClose } from "react-icons/md"
import { VscThreeBars } from "react-icons/vsc"

import { useProps } from "../../contexts/PropsContext"
import NavButton from "../buttons/NavButton"

import NavSide from "./NavSide"

const NavBar = ({ children }: { children: any }) => {
  const { authenticated, onUserLogout } = useProps()
  const [open, setOpen] = useState<boolean>(false)

  const onLogout = (): void => {
    onUserLogout()
  }

  return (
    <>
      <div className="border-b-2 border-neutral navbar bg-base-300">
        <button className="lg:hidden" onClick={() => setOpen(!open)}>
          {open ? (
            <MdClose className="w-6 h-6 text-grey hover:text-black dark:hover:text-white" />
          ) : (
            <VscThreeBars className="w-6 h-6 text-grey hover:text-black dark:hover:text-white" />
          )}
        </button>
        <div className="ml-auto">{authenticated ? <NavButton text="Logout" handler={onLogout} /> : ""}</div>
      </div>
      <NavSide open={open} setOpen={setOpen}>{children}</NavSide>
    </>
  )
}

export default NavBar
