import { useCallback, useEffect, FC } from "react"
import { useTranslation } from "react-i18next"
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

interface IProps {
  timeframe: (Date | null)[]
  setTimeframe: (dates: (Date | null)[]) => void
  onTimeframeChange: (timeframe: Date[]) => void
  disabled?: boolean
  dateFormat?: string
  showTime?: boolean
}

const TimeRangePicker: FC<IProps> = (props: IProps) => {
  const { t } = useTranslation()
  const { timeframe, setTimeframe, onTimeframeChange, disabled = false, dateFormat = "dd.MM.yyyy", showTime = false } = props

  const handleDateRangeChange = useCallback((dates: [Date | null, Date | null]): void => {
    if (Array.isArray(dates)) {
      const [start, end] = dates
      setTimeframe([start, end])
    }
  }, [])

  const handleTimeRangeChange = (title: string, date: Date | null): void => {
    if (title === "start") {
      setTimeframe([date, timeframe[1]])
    } else if (title === "end") {
      setTimeframe([timeframe[0], date])
    }
  }

  useEffect(() => {
    if (timeframe.filter(Boolean).length === 2) {
      onTimeframeChange(timeframe as Date[])
    }
  }, [onTimeframeChange, timeframe])

  if (showTime) {
    return (
      <div className="flex gap-2 items-center">
        <ReactDatePicker
          customInput={<input className="w-full sm:w-[160px] input input-bordered" />}
          disabled={disabled}
          placeholderText={t("From")}
          dateFormat={dateFormat}
          selectsStart
          startDate={timeframe[0]}
          endDate={timeframe[1]}
          selected={timeframe[0]}
          onChange={(date) => handleTimeRangeChange("start", date)}
          showMonthDropdown
          showYearDropdown
          showTimeSelect
          wrapperClassName="!w-auto"
        />
        <span>&#8211;</span>
        <ReactDatePicker
          customInput={<input className="w-full sm:w-[160px] input input-bordered" />}
          disabled={disabled}
          placeholderText={t("To")}
          dateFormat={dateFormat}
          selectsStart
          startDate={timeframe[0]}
          endDate={timeframe[1]}
          selected={timeframe[1]}
          onChange={(date) => handleTimeRangeChange("end", date)}
          showMonthDropdown
          showYearDropdown
          showTimeSelect
          wrapperClassName="!w-auto"
        />
      </div>
    )
  }

  return (
    <ReactDatePicker
      customInput={<input className="w-full sm:w-[280px] input input-bordered" />}
      disabled={disabled}
      placeholderText={t("Select range")}
      dateFormat={dateFormat}
      selectsRange
      startDate={timeframe[0]}
      endDate={timeframe[1]}
      selected={timeframe[0]}
      onChange={handleDateRangeChange}
      showMonthDropdown
      showYearDropdown
      wrapperClassName="w-full"
    />
  )
}

export default TimeRangePicker
