import { FC } from "react"
import TrashIcon from "assets/icons/trash-icon.svg"

interface IProps {
  id?: string
  value?: string
  setFieldValue?: any
}

const ImagePreview: FC<IProps> = (props) => {
  const { id, value, setFieldValue } = props
  return (
    <div className="w-full h-[84px] rounded flex justify-center items-center border border-gray-700 relative">
      {
        value ? 
          (
            <>
              <img className="w-full h-full rounded" src={value} />
              <div 
                className="w-5 h-5 absolute -top-2 -right-2 rounded-full flex justify-center items-center bg-[#f3f3f330] cursor-pointer"
                onClick={() => setFieldValue(id, null)}
              >
                <img className="w-3 h-3" src={TrashIcon} />
              </div>
            </>
          ) :
          'No Image'
      }
    </div>
  );
};

export default ImagePreview;
