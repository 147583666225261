import dayjs from "libs/dayjs"
import { startCase } from "lodash"

export enum EnumTimePeriod {
  today = "today",
  yesterday = "yesterday",
  thisWeek = "thisWeek",
  lastWeek = "lastWeek",
  thisMonth = "thisMonth",
  lastMonth = "lastMonth",
  last30Days = "last30Days",
  last6Months = "last6Months",
  last12Months = "last12Months",
  thisYear = "thisYear",
  lifetimeSystem = "lifetimeSystem",
  thisQuarter = "thisQuarter",
  custom = "custom"
}
const timePeriodOptionLabel = (v: EnumTimePeriod) => {
  if (v === EnumTimePeriod.lifetimeSystem) {
    return "Lifetime (System)"
  }
  return startCase(v)
}
export const TimePeriodOptions = Object.keys(EnumTimePeriod).map((period) => ({
  name: timePeriodOptionLabel(period as EnumTimePeriod),
  value: period
}))

const systemLaunch = dayjs(process.env.REACT_APP_LAUNCH_DATE, "DD.MM.YYYY").toDate()
export const getTimePeriod = (v: EnumTimePeriod): Date[] => {
  const now = new Date()
  const endOfNow = dayjs().endOf("day").toDate()
  switch (v) {
    case EnumTimePeriod.lifetimeSystem:
      return [systemLaunch, endOfNow]
    case EnumTimePeriod.today:
      return [dayjs().startOf("day").toDate(), endOfNow]
    case EnumTimePeriod.yesterday:
      return [dayjs().subtract(1, "day").startOf("day").toDate(), dayjs().startOf("day").toDate()]
    case EnumTimePeriod.thisWeek:
      return [dayjs().startOf("isoWeek").toDate(), endOfNow]
    case EnumTimePeriod.lastWeek:
      return [dayjs().startOf("isoWeek").subtract(1, "week").toDate(), dayjs().startOf("isoWeek").toDate()]
    case EnumTimePeriod.thisMonth:
      return [dayjs().startOf("month").toDate(), endOfNow]
    case EnumTimePeriod.lastMonth:
      return [dayjs().startOf("month").subtract(1, "month").toDate(), dayjs().startOf("month").toDate()]
    case EnumTimePeriod.thisQuarter:
      return [dayjs().startOf("quarter").startOf("day").toDate(), endOfNow]
    case EnumTimePeriod.thisYear:
      return [dayjs().startOf("year").toDate(), endOfNow]
    case EnumTimePeriod.last30Days:
      return [dayjs().startOf("day").subtract(1, "month").toDate(), endOfNow]
    case EnumTimePeriod.last12Months:
      return [dayjs().startOf("day").subtract(12, "month").toDate(), endOfNow]
    case EnumTimePeriod.last6Months:
      return [dayjs().subtract(6, "months").toDate(), endOfNow]
    case EnumTimePeriod.custom:
      return [now, now]
    default:
      return [new Date(0), endOfNow]
  }
}
