import React, { FC, useState } from "react"
import { FaEye, FaEyeSlash } from "react-icons/fa"

interface IProps {
  title: string
  placeholder: string
  value: string
  handler: any
  onEnter: any
}

const InputFormPassword: FC<IProps> = (props) => {
  const { title, placeholder, value, handler, onEnter } = props

  const [hidden, setHidden] = useState<boolean>(true)

  return (
    <>
      <div className="w-full grid grid-cols-1 gap-2">
        <div className="flex items-center justify-start gap-2">
          <span className="text-16 font-bold text-grey-light">{title}</span>
          <span className="text-red text-16 font-bold">*</span>
        </div>
        <div className="w-full grid grid-cols-[1fr,auto] items-center rounded-6 bg-grey-light-2 dark:bg-grey-med-2">
          <input
            type={hidden ? "password" : "text"}
            className="w-full text-black p-2 dark:text-white rounded-l-6 placeholder-grey-light text-16 font-bold bg-grey-light-2 dark:bg-grey-med-2"
            placeholder={placeholder}
            value={value}
            onChange={(e) => handler(e.target.value)}
            onKeyUp={(e) => (onEnter ? onEnter(e.key) : null)}
          />
          <div onClick={() => setHidden(!hidden)} className="cursor-pointer flex items-center justify-center p-2">
            {hidden ? (
              <FaEye className="text-grey-light hover:text-black dark:hover:text-white" />
            ) : (
              <FaEyeSlash className="text-grey-light hover:text-black dark:hover:text-white" />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default InputFormPassword
