import React, { createContext, useEffect, useState } from "react"
import { io } from "socket.io-client"
import i18n from "../i18n"
import authService from "services/auth/auth.service"
import userService from "services/user/user.service"
import {
  getterAuthenticated,
  getterDarkMode,
  getterLanguage,
  setterAuthenticated,
  setterDarkMode,
  setterLanguage
} from "../storage/cookies"

import { createCtx } from "./Context"
import { User, UserRole } from "services/user/type"
import { toast } from "react-toastify"
import { parseError } from "util/api"
import kycService from "services/kyc/kyc.service"
import { EVENT_KYC_FILES_COUNT } from "constants/socket-events"

type PropsContextType = {
  user: any
  authenticated: boolean
  kycFilesCount: number
  setUser: any
  onUserLogin: any
  onUserLogout: any
  onKYCFilesCount: any
  darkMode: boolean
  onDarkModeChange: any
  language: string
  setLanguage: any
}

export const [useProps, CtxProvider] = createCtx<PropsContextType>()

export const PropsContext = createContext<PropsContextType | undefined>(undefined)

export const PropsProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(null)
  const [darkMode, setDarkMode] = useState<boolean>(getterDarkMode())
  const [language, setLanguage] = useState<string>(getterLanguage())
  const [authenticated, setAuthenticated] = useState<boolean>(false)
  const [initialised, setInitialised] = useState(false)
  const [kycFilesCount, setKYCFilesCount] = useState(0)
  const [socket, setSocket] = useState<any>()

  useEffect(() => {
    const onLoad = async (): Promise<void> => {
      if (getterAuthenticated()) {
        const user = await userService.getUser()
        if (user && user.role === UserRole.ADMIN) {
          setUser(user)
          setAuthenticated(true)
        } else {
          setterAuthenticated(false)
        }
      }
      setInitialised(true)

      if (process.env.REACT_APP_BASE_API_URL) {
        setSocket(
          io(process.env.REACT_APP_BASE_API_URL, {
            query: {},
            rejectUnauthorized: false,
            transports: ["websocket"],
            withCredentials: true
            // path: "/api/socket.io"
          })
        )
      }
    }

    onLoad()
  }, [])

  useEffect(() => {
    onKYCFilesCount()
  }, [])

  useEffect(() => {
    if (socket) {
      onSocket()
    }
  }, [socket])

  useEffect(() => {
    setterLanguage(language)
    i18n.changeLanguage(language)
  }, [language])

  const onSocket = () => {
    socket.on("connect", () => {
      // console.log("Socket connected")
    })
    socket.on("connect_error", (err: any) => {
      // eslint-disable-next-line no-console
      console.error(err)
    })

    socket.on(EVENT_KYC_FILES_COUNT, (res: any) => {
      setKYCFilesCount(res.count ?? 0)
    })
  }

  const onUserLogin = async (username: string, password: string): Promise<User | null> => {
    try {
      await authService.signIn({ username, password })
      const user = await userService.getUser()
      if (user) {
        if (user.role === UserRole.ADMIN || user.role === UserRole.SUPPORT) {
          setUser(user)
          setterAuthenticated(true)
          setAuthenticated(true)
          return user
        }
        toast.error("You don't have permission to access this page")
        await authService.logout()
      }
      return null
    } catch (error) {
      parseError(error)
      return null
    }
  }

  const onUserLogout = async (): Promise<void> => {
    await authService.logout()
    setterAuthenticated(false)
    setUser(null)
    setAuthenticated(false)
  }

  const onDarkModeChange = (value: boolean): void => {
    setDarkMode(value)
    setterDarkMode(value)
  }

  const onKYCFilesCount = async () => {
    const response = await kycService.getKYCFilesCount()
    setKYCFilesCount(response.count)
  };

  if (!initialised)
    return (
      <div className="grid place-items-center h-screen">
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    )

  return (
    <>
      <CtxProvider
        value={{
          user,
          authenticated,
          kycFilesCount,
          setUser,
          onUserLogin,
          onUserLogout,
          onKYCFilesCount,
          darkMode,
          onDarkModeChange,
          language,
          setLanguage
        }}
      >
        {children}
      </CtxProvider>
    </>
  )
}

export default PropsProvider
