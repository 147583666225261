import { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { toast } from "react-toastify";
import { enumCMSTypes } from "constants/cms";
import DropdownMenu from "components/inputs/DropdownMenu"
import { CMSService } from "services/cms/cms.service";
import { enumTenant, tenantOptions } from "constants/bonus";
import { CMSTextActionResponse } from "services/cms/type";

const CMSTextEditor = () => {
  const [termsContent, setTermsContent] = useState('')
  const [tenantId, setTenantId] = useState(enumTenant.Slotwhales)
  const [cmsTextData, setCMSTextData] = useState<CMSTextActionResponse | null>(null)
  const editorRef = useRef<any>(null);

  useEffect(() => {
    if (tenantId) {
      getCMSTextData()
    } else {
      setTermsContent('')
      setCMSTextData(null)
    }
  }, [tenantId])

  const handleChangeTermsContent = (value: string) => {
    setTermsContent(value)
  }

  const handleSaveTermsContent = async () => {
    if (termsContent && tenantId) {
      if (cmsTextData) {
        const res = await CMSService.editCMSText(cmsTextData?.id, {
          type: enumCMSTypes.TERMS_CONTENT,
          content: termsContent,
          active: true,
          tenant_id: tenantId,
        })
        if (res) {
          toast.success("Terms content saved successfully")
          setCMSTextData(res)
        }
      } else {
        const res = await CMSService.createCMSText({
          type: enumCMSTypes.TERMS_CONTENT,
          content: termsContent,
          active: true,
          tenant_id: tenantId,
        })
        if (res) {
          toast.success("Terms content saved successfully")
          setCMSTextData(res)
        }
      }
    } else {
      toast.error("Please complete the inputs")
    }
  };

  const getCMSTextData = async () => {
    try {
      const res: any = await CMSService.getCMSTextData({
        type: enumCMSTypes.TERMS_CONTENT,
        active: true,
        tenant: tenantId
      })

      if (res.length) {
        setCMSTextData(res[0])
        setTermsContent(res[0]?.content)
      } else {
        setCMSTextData(null)
        setTermsContent('')
      }
    } catch(err) {
      toast.error("Failed to load data")
    }
  };

  return (
    <>
      <Editor 
        // eslint-disable-next-line no-return-assign
        onInit={(_evt, editor) => editorRef.current = editor}
        apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
        initialValue="<p>This is the initial content of the editor.</p>"
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'a11ychecker','advlist','advcode','advtable','autolink','checklist','export',
            'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
            'powerpaste','fullscreen','formatpainter','insertdatetime','media','table','help','wordcount'
          ],
          toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
            'alignleft aligncenter alignright alignjustify | ' +
            'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }} 
        value={termsContent}
        onEditorChange={handleChangeTermsContent}
      />
      <div className="w-full">
        <DropdownMenu
          title="Tenant"
          options={tenantOptions}
          value={tenantId}
          onChange={(e: any) => setTenantId(e.target.value)}
          placeholder="Please choose an option"
          altLabelOne={!tenantId ? "This field is required" : ""}
        />
      </div>
      <div className="flex flex-row items-end justify-end">
        <button className="btn btn-primary" onClick={handleSaveTermsContent}>
          Save
        </button>
      </div>
    </>
  )
}

export default CMSTextEditor
