import { Maybe } from "types/common"

export type Comments = {
  id: string
  user_id: string
  commentor: string
  comment: string
  category: string
  created_at: string
}

export enum BonusType {
  DEPOSIT = "DEPOSIT",
  CUSTOM = "CUSTOM",
  PROMO = "PROMO"
}

export type Bonus = {
  id: string
  type: BonusType
  title: string
  bonus_percent: number
  max_usage_per_user: number
  max_bonus_amount: number
  active: boolean
  days_to_expire: number
  wager_requirements: number
  min_deposit: Maybe<number>
  zero_out_threshold: Maybe<number>
  bet_max_limit: Maybe<number>
  withdraw_max_limit: Maybe<number>
  created_at: Date
  updated_at: Date
}

export enum CardGateway {
  SP = "SP",
  FS = "FS",
  FV0 = "FV0",
  GB1 = "GB1",
  GB2 = "GB2",
  FV1 = "FV1",
  FV2 = "FV2"
}
