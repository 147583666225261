import { TableDataType } from "./eums"
import { TableColumnsInterface } from "./interfaces"

export enum enumPromoTypes {
  CASH_BALANCE = "CASH_BALANCE",
  DEPOSIT_BONUS = "DEPOSIT_BONUS",
  NO_DEPOSIT_BONUS = "NO_DEPOSIT_BONUS"
}

export const tablePromoCodesColumns: TableColumnsInterface[] = [
  {
    value: "id",
    title: "Id",
    format: TableDataType.NUMBER,
    sorting: true
  },
  {
    value: "tenant_id",
    title: "Tenant",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "code",
    title: "Promo Code",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "active",
    title: "Activated",
    format: TableDataType.BOOLEAN,
    sorting: true
  },
  {
    value: "type",
    title: "Type",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "usd_amount",
    title: "USD AMOUNT",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "bonus.title",
    title: "Bonus",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "bonus_amount",
    title: "BONUS AMOUNT",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "wager_requirements",
    title: "Wager Requirements",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "days_to_expire",
    title: "Days to expire",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "used_times",
    title: "Used times",
    format: TableDataType.NUMBER,
    sorting: false
  },
  {
    value: "allowed_uses",
    title: "Allowed uses",
    format: TableDataType.NUMBER,
    sorting: true
  }
]

export const promoTypes = [
  { value: enumPromoTypes.CASH_BALANCE, name: enumPromoTypes.CASH_BALANCE },
  { value: enumPromoTypes.DEPOSIT_BONUS, name: enumPromoTypes.DEPOSIT_BONUS },
  { value: enumPromoTypes.NO_DEPOSIT_BONUS, name: enumPromoTypes.NO_DEPOSIT_BONUS }
];
