import { GetSingleUser } from "services/user/type"
import { formatFiatCurrency } from "util/formatter"
import { CardWrapper } from "./CardWrapper"

type UserRakeBackCardProps = {
  user: GetSingleUser
}
export const UserRakeBackCard = ({ user }: UserRakeBackCardProps) => {
  return (
    <CardWrapper>
      <span>
        Current rakeback: <span className="text-accent">{formatFiatCurrency(user.rakeback_balance)}</span>
      </span>
      <span>
        Earned rakeback: <span className="text-accent">{formatFiatCurrency(user.rakeback_earned)}</span>
      </span>
      <span>
        Current affiliate: <span className="text-accent">{formatFiatCurrency(user.affiliate_balance)}</span>
      </span>
      <span>
        Earned affiliate: <span className="text-accent">{formatFiatCurrency(user.affiliate_earned)}</span>
      </span>
    </CardWrapper>
  )
}
