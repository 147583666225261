import { FC, useCallback, useEffect } from "react"
import { debounce } from "lodash"

interface Iprops {
  title?: string
  onChange?: any
  placeholder?: string
  disabled?: boolean
  id?: string
  type?: string
  invalid?: boolean
  altLabelOne?: string
  altLabelTwo?: string
  altLabelThree?: string
}

const SearchInput: FC<Iprops> = (props) => {
  const { title, onChange, placeholder, disabled, id, type, invalid, altLabelOne, altLabelTwo, altLabelThree } = props
  useEffect(() => {
    return () => {
      debouncedSearchHandler.cancel()
    }
  }, [])

  const handleSearchInput = useCallback((text: string): void => onChange(text.trim()), [])

  const debouncedSearchHandler = useCallback(debounce(handleSearchInput, 800), [])

  return (
    <div className="w-full form-control">
      <label className="label">
        <span className={`label-text ${invalid ? "text-error" : ""}`}>{title}</span>
        <span className={`label-text-alt ${invalid ? "text-error" : ""}`}>{altLabelOne}</span>
      </label>
      <input
        id={id}
        type={type || "text"}
        onChange={(e) => debouncedSearchHandler(e.target.value)}
        placeholder={placeholder}
        className={`w-full input input-bordered ${invalid ? "input-error" : ""}`}
        disabled={disabled}
      />
      <label>
        <span className={`label-text-alt ${invalid ? "text-error" : ""}`}>{altLabelTwo}</span>
        <span className={`label-text-alt ${invalid ? "text-error" : ""}`}>{altLabelThree}</span>
      </label>
    </div>
  )
}

export default SearchInput
