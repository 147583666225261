import { FC, useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { enumPromoTypes, promoTypes } from "constants/promo-codes"
import InputField from "../inputs/InputField"
import DropdownMenu from "../inputs/DropdownMenu"
import TitledCheckbox from "../inputs/TitledCheckbox"
import Modal from "./Modal"
import { enumTenant, tenantOptions } from "constants/bonus"
import { ISelectOption } from "constants/interfaces"
import { CMSService } from "services/cms/cms.service"
import { enumCMSTypes } from "constants/cms"

interface FormValuesType {
  id: string
  code: string
  type: string
  active: boolean
  allowed_uses: number
  tenant_id: string
  bonus_id?: string
  bonus_amount?: number
  usd_amount?: number
  wager_requirements?: number
  days_to_expire?: number
}

const typeRelevants = ["usd_amount", "bonus_id", "bonus_amount", "wager_requirements", "days_to_expire"]

interface IProps {
  data?: any
  open: boolean
  handler: any
  onSubmit: (values: FormValuesType) => void
  promoBonuses: any
}

const validationSchema = Yup.object().shape({
  code: Yup.string().required(),
  type: Yup.string().required("This field is required"),
  tenant_id: Yup.string().required("This field is required"),
  allowed_uses: Yup.number().integer().min(0, "The value must not be a negative number"),
  bonus_id: Yup.string().when("type", {
    is: enumPromoTypes.DEPOSIT_BONUS,
    then: Yup.string().required("Please choose one")
  }),
  usd_amount: Yup.number().when("type", {
    is: enumPromoTypes.CASH_BALANCE,
    then: Yup.number().required().min(1)
  }),
  bonus_amount: Yup.number().when("type", {
    is: enumPromoTypes.NO_DEPOSIT_BONUS,
    then: Yup.number().required().min(1)
  }),
  wager_requirements: Yup.number().when("type", {
    is: enumPromoTypes.NO_DEPOSIT_BONUS,
    then: Yup.number().required().min(1)
  }),
  days_to_expire: Yup.number().when("type", {
    is: enumPromoTypes.NO_DEPOSIT_BONUS,
    then: Yup.number().required().min(1)
  })
})

const ModalPromoCode: FC<IProps> = (props) => {
  const { data, open, handler, onSubmit, promoBonuses } = props

  useEffect(() => {
    formik.resetForm()
  }, [open])

  useEffect(() => {
    if (data) {
      formik.setValues({
        ...data,
        bonus_id: data.type === enumPromoTypes.DEPOSIT_BONUS ? data.bonus_id ?? "" : undefined,
        usd_amount: data.type === enumPromoTypes.CASH_BALANCE ? data.usd_amount ?? 0 : undefined,
        bonus_amount: data.type === enumPromoTypes.NO_DEPOSIT_BONUS ? data.bonus_amount ?? 0 : undefined,
        wager_requirements: data.type === enumPromoTypes.NO_DEPOSIT_BONUS ? data.wager_requirements ?? 0 : undefined,
        days_to_expire: data.type === enumPromoTypes.NO_DEPOSIT_BONUS ? data.days_to_expire ?? 0 : undefined
      })
    }
  }, [data])

  const formik = useFormik<FormValuesType>({
    initialValues: {
      id: "",
      code: "",
      type: enumPromoTypes.CASH_BALANCE,
      active: false,
      allowed_uses: 0,
      tenant_id: enumTenant.Slotwhales,
      usd_amount: 0,
    },
    onSubmit: async (values, formikHelpers): Promise<void> => {
      formikHelpers.setTouched({})
      await onSubmit({ ...values })
      handler()
    },
    validationSchema
  })

  const handleChangePromoCodeType = (event: any) => {
    const typeValue = event.target.value
    handleChangePromoVariables(typeValue)
  }

  const handleChangePromoVariables = (type: string) => {
    formik.setFieldValue("type", type)
    typeRelevants.forEach((trItem) => {
      if (type === enumPromoTypes.CASH_BALANCE && trItem === "usd_amount") {
        formik.setFieldValue(trItem, 0)
      } else if (type === enumPromoTypes.DEPOSIT_BONUS && trItem === "bonus_id") {
        formik.setFieldValue(trItem, "")
      } else if (
        type === enumPromoTypes.NO_DEPOSIT_BONUS &&
        (trItem === "bonus_amount" || trItem === "wager_requirements" || trItem === "days_to_expire")
      ) {
        formik.setFieldValue(trItem, 0)
      } else {
        formik.setFieldValue(trItem, undefined)
      }
    })
  }

  return (
    <Modal open={open} handler={handler}>
      <div className="relative flex flex-col justify-between modal-box">
        <div className="flex flex-row space-x-4 mt-3">
          <div className="w-full">
            <InputField
              id="code"
              title="Promo Code"
              value={formik.values.code}
              onChange={formik.handleChange}
              invalid={!!(formik.touched.code && formik.errors.code)}
              altLabelTwo={formik.touched.code ? formik.errors.code : ""}
            />
          </div>
          <div className="w-full">
            <InputField
              id="allowed_uses"
              title="Allowed Uses"
              type="number"
              value={formik.values.allowed_uses}
              onChange={formik.handleChange}
              invalid={!!(formik.touched.allowed_uses && formik.errors.allowed_uses)}
              altLabelTwo={formik.touched.allowed_uses ? formik.errors.allowed_uses : ""}
            />
          </div>
        </div>
        <div className="flex flex-row space-x-4 mt-3">
          <div className="w-full">
            <TitledCheckbox id="active" title="Active" checked={formik.values.active} onChange={formik.handleChange} />

            <DropdownMenu
              id="tenant_id"
              title="Tenant"
              options={tenantOptions}
              value={formik.values.tenant_id}
              onChange={formik.handleChange}
              placeholder="Please choose an option"
              invalid={Boolean(formik.touched.tenant_id && formik.errors.tenant_id)}
              altLabelOne={formik.touched.tenant_id ? formik.errors.tenant_id : ""}
            />

            <DropdownMenu
              id="type"
              title="Promo Code Type"
              options={promoTypes}
              value={formik.values.type}
              onChange={handleChangePromoCodeType}
              placeholder="Please choose an option"
              invalid={Boolean(formik.touched.type && formik.errors.type)}
              altLabelOne={formik.touched.type ? formik.errors.type : ""}
            />

            {formik.values.type === enumPromoTypes.CASH_BALANCE ? (
              <InputField
                id="usd_amount"
                title="Amount"
                altLabelOne={`$${(formik.values.usd_amount || 0) / 100}`}
                type="number"
                value={formik.values.usd_amount ?? 0}
                onChange={formik.handleChange}
                invalid={!!(formik.touched.usd_amount && formik.errors.usd_amount)}
                altLabelTwo={formik.touched.usd_amount ? formik.errors.usd_amount : ""}
              />
            ) : null}

            {formik.values.type === enumPromoTypes.DEPOSIT_BONUS ? (
              <DropdownMenu
                id="bonus_id"
                title="Bonus Offer"
                options={promoBonuses.map((pBonus: any) => ({ name: pBonus.title, value: pBonus.id }))}
                value={formik.values.bonus_id ?? ''}
                onChange={formik.handleChange}
                placeholder="Please choose an option"
                invalid={Boolean(formik.touched.bonus_id && formik.errors.bonus_id)}
                altLabelOne={formik.touched.bonus_id ? formik.errors.bonus_id : ""}
              />
            ) : null}

            {formik.values.type === enumPromoTypes.NO_DEPOSIT_BONUS ? (
              <>
                <InputField
                  id="bonus_amount"
                  title="Bonus Amount"
                  altLabelOne={`$${(formik.values.bonus_amount || 0) / 100}`}
                  type="number"
                  value={formik.values.bonus_amount ?? 0}
                  onChange={formik.handleChange}
                  invalid={!!(formik.touched.bonus_amount && formik.errors.bonus_amount)}
                  altLabelTwo={formik.touched.bonus_amount ? formik.errors.bonus_amount : ""}
                />

                <InputField
                  id="wager_requirements"
                  title="Wagering Requirements"
                  type="number"
                  value={formik.values.wager_requirements ?? 0}
                  onChange={formik.handleChange}
                  invalid={!!(formik.touched.wager_requirements && formik.errors.wager_requirements)}
                  altLabelTwo={formik.touched.wager_requirements ? formik.errors.wager_requirements : ""}
                />

                <InputField
                  id="days_to_expire"
                  title="Days to Expire"
                  type="number"
                  value={formik.values.days_to_expire ?? 0}
                  onChange={formik.handleChange}
                  invalid={!!(formik.touched.days_to_expire && formik.errors.days_to_expire)}
                  altLabelTwo={formik.touched.days_to_expire ? formik.errors.days_to_expire : ""}
                />
              </>
            ) : null}
          </div>
        </div>
        <div className="modal-action">
          <button className="btn btn-secondary" onClick={handler}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={formik.submitForm}>
            Save
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalPromoCode
