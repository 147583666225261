import { FC, useState } from "react"
import DropdownButton from "../buttons/DropdownButton"
import { TableColumnsInterface } from "constants/interfaces"
import TitledCheckbox from "components/inputs/TitledCheckbox"

interface IProps {
  columns: TableColumnsInterface[]
  setColumns: (columns: TableColumnsInterface[]) => void
}

const TableColumnsFilter: FC<IProps> = (props) => {
  const { columns, setColumns } = props
  const [open, setOpen] = useState<boolean>(false)

  const handleValueChange = (column: TableColumnsInterface): void => {
    setColumns(columns.map((cItem) => (cItem.value === column.value ? { ...cItem, selected: !column.selected } : cItem)))
  }

  return (
    <div className="relative">
      <DropdownButton value="Columns Filter" handler={() => setOpen(!open)} />
      {open ? (
        <div className="mt-2 absolute bg-grey-light-2 dark:bg-grey-med-2 z-20">
          <ul className="rounded-md text-md text-black dark:text-white max-h-[300px] overflow-auto">
            {columns.map((column, key) => (
              <li key={key} className="px-4 py-2 hover:text-grey-light cursor-pointer">
                <TitledCheckbox title={column.title} checked={column.selected ?? false} onChange={() => handleValueChange(column)} />
              </li>
            ))}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default TableColumnsFilter
