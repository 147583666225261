import { useState } from "react"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import Table from "../components/tables/Table"
import { URL } from "../constants/api/requests"
import ModalConfirmAction from "../components/modals/ModalConfirmAction"
import transactionService from "services/transaction/transaction.service"
import { formatFiatCurrency } from "../util/formatter"
import clsx from "clsx"
import { tableBankTransfersColumns } from "constants/transactions"
import { Coin } from "services/user/type"
import { ModalDeclineAction } from "components/modals/ModalDeclineAction"
import { useNavigate } from "react-router-dom"
import SingleOptTimeRangePicker from "components/date/SingleOptTimePeriodPicker"

enum Tabs {
  SUBMITTED = "SUBMITTED",
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  DECLINED = "DECLINED"
}

export const BankTransfers = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.SUBMITTED)
  const { t }: { t: any } = useTranslation(["landing"], { useSuspense: false })
  const [declineRow, setDeclineRow] = useState<any>(null)
  const [approveRow, setApproveRow] = useState<any>(null)
  const [tableUpdatedAt, setTableUpdatedAt] = useState<Date | number>(Date.now())
  const [timeframe, setTimeframe] = useState<(Date | null)[]>([])

  const handleChangeTimeFrame = (dates: (Date | null)[]) => setTimeframe(dates)

  const onOpenApproveRow = (row: any): void => {
    setApproveRow(row)
  }

  const approveRowHandler = (): void => {
    setApproveRow(null)
    setTableUpdatedAt(Date.now())
  }

  const onApproveTransaction = async (tx: any): Promise<void> => {
    if (!tx?.id) return

    try {
      const response = await transactionService.approveBankTransfer(tx.id, tx.status)
      if (response) {
        // @ts-ignore
        toast.success(t("success_action_transaction", { actionText: "Approved" }))
      }
    } catch (err: any) {
      toast.error(err?.message || "Failed")
    }
  }

  const onOpenDeclineRow = (row: any): void => {
    setDeclineRow(row)
  }

  const declineRowHandler = (): void => {
    setDeclineRow(null)
    setTableUpdatedAt(Date.now())
  }

  const onDeclineBankTransfer = async (txId: string, reason: string): Promise<void> => {
    const response = await transactionService.declineTransaction(txId, reason)
    if (response) {
      // @ts-ignore
      toast.success(t("success_action_transaction", { actionText: t("declined") }))
    }
  }

  const actions = [
    {
      name: t("decline"),
      condition: (row: any) => [Tabs.SUBMITTED, Tabs.PENDING].includes(row?.status) && row?.type === "WITHDRAWAL",
      handler: onOpenDeclineRow,
      buttonColor: "btn-secondary"
    },
    {
      name: "Approve",
      condition: (row: any) => [Tabs.SUBMITTED, Tabs.PENDING].includes(row?.status) && row?.type === "WITHDRAWAL",
      handler: onOpenApproveRow
    }
  ]

  const onRowClick = (row: any): void => {
    if (row?.user_id) {
      navigate(URL.USER.replace(":id", row.user_id))
    }
  }

  return (
    <div className="w-full p-8">
      <div className="w-full grid grid-col-1 gap-8 px-8">
        <div className="flex items-center justify-center text-xl text-black dark:text-white">Bank Transfers (SEPA)</div>

        <div className="flex items-center justify-center">
          <div className="tabs">
            {Object.keys(Tabs).map((tab) => (
              <a
                key={`tab-${tab}`}
                onClick={() => setActiveTab(tab as Tabs)}
                className={clsx("tab tab-bordered", {
                  "tab-active": tab === activeTab
                })}
              >
                {tab}
              </a>
            ))}
          </div>
        </div>

        <div className="max-w-md">
          <SingleOptTimeRangePicker onTimeframeChange={handleChangeTimeFrame} />
        </div>

        <Table
          title={`${activeTab} Bank Transfers`}
          handler={transactionService.getTransactionsTable}
          columns={tableBankTransfersColumns}
          actions={actions}
          updatedAt={tableUpdatedAt}
          filters={{
            type: "WITHDRAWAL",
            status: activeTab,
            coin: Coin.USD,
            createdAt: timeframe
          }}
          onRowClick={onRowClick}
          isExport={activeTab === Tabs.COMPLETED}
        />

        <ModalConfirmAction
          data={approveRow}
          handler={approveRowHandler}
          text={t("modal_conformation_transaction", {
            actionText: "approve",
            usd_amount: formatFiatCurrency(approveRow?.usd_amount),
            user: approveRow?.user
          })}
          actionText="Approve"
          actionHandler={onApproveTransaction}
        />
        <ModalDeclineAction
          data={declineRow}
          handler={declineRowHandler}
          text={t("modal_conformation_transaction", {
            actionText: "decline",
            usd_amount: formatFiatCurrency(declineRow?.usd_amount),
            user: declineRow?.user
          })}
          actionText={t("decline")}
          actionHandler={onDeclineBankTransfer}
        />
      </div>
    </div>
  )
}
