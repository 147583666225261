import React, { FC, useState } from "react"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import InputForm from "../inputs/InputForm"
import NavButton from "../buttons/NavButton"
import { URL } from "../../constants/api/requests"
import { useProps } from "../../contexts/PropsContext"
import InputFormPassword from "../inputs/InputFormPassword"
import { User, UserRole } from "services/user/type"

const Login: FC = () => {
  const navigate = useNavigate()
  const { onUserLogin, user } = useProps()
  const { t } = useTranslation("landing", { useSuspense: false })

  const [username, setUsername] = useState<string>("")
  const [password, setPassword] = useState<string>("")

  const onEnter = async (key: string): Promise<void> => {
    if (key === "Enter") {
      await onLogin()
    }
  }

  const onLogin = async (): Promise<void | number | string> => {
    if (!username) {
      // @ts-ignore
      return toast.error(t("enter_email_or_username"))
    }

    if (!password) {
      // @ts-ignore
      return toast.error(t("enter_a_password"))
    }

    onUserLogin(username, password)
      .then((result: User) => {
        if (result) {
          // @ts-ignore
          toast.success(t("success_login"))
          return result.role === UserRole.ADMIN ? navigate(URL.DASHBOARD) : navigate(URL.USERS)
        }
      })
      // @ts-ignore
      .catch(() => toast.error(t("login_failed")))
  }

  return (
    <>
      <div className="max-w-full w-[400px] grid grid-cols-1 gap-4 rounded-md overflow-hidden bg-grey-light-3 dark:bg-grey-med">
        <div className="w-full px-4 pt-4 grid grid-cols-1 gap-4">
          <InputForm
            title={t("email_or_username")}
            placeholder={t("enter_email_or_username")}
            value={username}
            handler={setUsername}
            onEnter={onEnter}
          />
          <InputFormPassword
            title={t("password")}
            placeholder={t("enter_password")}
            value={password}
            handler={setPassword}
            onEnter={onEnter}
          />
        </div>
        <div className="w-full flex justify-center p-4">
          <NavButton className="w-full" text="Log in" handler={onLogin} />
        </div>
      </div>
    </>
  )
}

export default Login
