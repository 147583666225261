import React, { FC } from "react"

interface IProps {
  title: string
  placeholder: string
  value: string
  handler: any
  onEnter: any
}

const InputForm: FC<IProps> = (props) => {
  const { title, placeholder, value, handler, onEnter } = props
  return (
    <>
      <div className="w-full grid grid-cols-1 gap-2">
        <div className="w-full flex items-center justify-start gap-2">
          <span className="text-16 font-bold text-grey-light">{title}</span>
          <span className="text-16 font-bold text-red ">*</span>
        </div>
        <div className="w-full">
          <input
            type="text"
            className="w-full text-black dark:text-white rounded-6 placeholder-grey-light bg-grey-light-2 dark:bg-grey-med-2 text-16 font-bold p-2"
            placeholder={placeholder}
            value={value}
            onChange={(e) => handler(e.target.value)}
            onKeyUp={(e) => (onEnter ? onEnter(e.key) : null)}
          />
        </div>
      </div>
    </>
  )
}

export default InputForm
