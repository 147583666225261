import axios from "axios"
import { Cookies } from "react-cookie"
import { toast } from "react-toastify"
// import authService from "services/auth/auth.service"

export const cookies = new Cookies()
export const setCookies = cookies.set

const getAccessToken = () => {
  const access_token = cookies.get("access_token")
  return access_token
}

// const getRefreshToken = () => {
//   const refresh_token = cookies.get("refresh_token")
//   return refresh_token
// }

const config = {
  baseURL: process.env.REACT_APP_BASE_API_URL || "http://localhost:3001",
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    "Access-Control-Allow-Origin": "*"
  }
}

const api = axios.create(config)

api.interceptors.request.use(
  (config) => {
    const access_token = getAccessToken()
    if (config.headers && access_token && !config.url?.includes("refresh_token"))
      config.headers["Authorization"] = `Bearer ${access_token ?? ""}`

    if (config.url?.includes("refresh_token")) (config as any)._retry = true

    config.withCredentials = true

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    // const originalConfig = error.config

    // if (error.response) {
    //   if (error.response.status === 401 && !originalConfig._retry) {
    //     originalConfig._retry = true
    //     try {
    //       const data = await authService.refreshToken(getRefreshToken())
    //       if (!data) return Promise.reject(error)

    //       authService.registerRefreshToken(data.refresh_token)
    //       authService.registerAccessToken(data.access_token)

    //       api.defaults.headers.common["Authorization"] = data.access_token

    //       return api(originalConfig)
    //     } catch (_error: any) {
    //       if (_error.response && _error.response.data) {
    //         return Promise.reject(_error.response.data)
    //       }

    //       return Promise.reject(_error)
    //     }
    //   }
    // }

    return Promise.reject(error.response)
  }
)

export default api

export const parseError = (error: any, defaultMessage = "Something went wrong.") => {
  toast.error(error?.response?.data?.message || error?.data?.message || defaultMessage)
}
