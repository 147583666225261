import { useState } from "react"
import { useTranslation } from "react-i18next"
import clsx from "clsx";
import Table from "components/tables/Table"
import SearchInput from "components/search/SearchInput"
import { SortOrderBy, SortOrderType } from "constants/eums";
import { enumCMSTypes, tableCMSColumns } from "constants/cms";
import { CMSService } from "services/cms/cms.service";
import ModalCMS from "components/modals/ModalCMS";
import { CreateCMSPayload, EditCMSPayload } from "services/cms/type";
import CMSTextEditor from "components/editors/CMSTextEditor";

const CMSPage = () => {
  const { t } = useTranslation(["landing"], { useSuspense: false })

  const [editRow, setEditRow] = useState(null)
  const [searchValue, setSearchValue] = useState<string>("")
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false)
  const [tableUpdatedAt, setTableUpdatedAt] = useState<Date | number>(Date.now())
  const [currentTab, setCurrentTab] = useState<enumCMSTypes>(enumCMSTypes.HOMEPAGE_BANNER)

  const onEditRow = (row: any): void => {
    setEditRow(row)
    setOpenEditModal(true)
  }

  const editRowHandler = (): void => {
    setEditRow(null)
    setOpenEditModal(false)
  }

  const createPromoCodeHandler = (): void => {
    setOpenCreateModal(false)
  }

  const onEditModalSubmit = async ({ id, ...data }: EditCMSPayload & { id: string }): Promise<void> => {
    await CMSService.editCMS(id, data)
    setTableUpdatedAt(Date.now())
  }

  const onCreateModalSubmit = async ({ id: _, ...data }: CreateCMSPayload & { id: null | string }): Promise<void> => {
    await CMSService.createCMS(data)
    setTableUpdatedAt(Date.now())
  }

  const actions = [
    {
      name: t("edit"),
      handler: onEditRow
    }
  ]

  return (
    <div className="grid w-full gap-8 p-8 grid-col-1">
      <div className="tabs">
        {Object.keys(enumCMSTypes).map((tab, index) => (
          <a
            key={index}
            onClick={() => setCurrentTab(tab as enumCMSTypes)}
            className={clsx("tab tab-bordered", {
              "tab-active": currentTab === tab
            })}
          >
            {tab}
          </a>
        ))}
      </div>

      {
        currentTab === enumCMSTypes.TERMS_CONTENT ? (
          <CMSTextEditor />
        ) : (
          <>
            <div className="flex flex-row items-end justify-between">
              <div className="max-w-sm">
                <SearchInput title={t("search")} onChange={setSearchValue} />
              </div>
              <button className="btn btn-primary" onClick={() => setOpenCreateModal(true)}>
                {t("create")}
              </button>
            </div>
      
            <Table
              title={t("banners")}
              handler={CMSService.getCMSTable}
              columns={tableCMSColumns}
              actions={actions}
              updatedAt={tableUpdatedAt}
              filters={{ q: searchValue, type: currentTab }}
              defaultSortOrder={SortOrderType.ASC}
              defaultSortOrderBy={SortOrderBy.ID}
              onRowClick={(row: any) => onEditRow(row)}
            />
          </>
        )
      }

      <ModalCMS
        open={openEditModal}
        data={editRow}
        handler={editRowHandler}
        onSubmit={onEditModalSubmit}
        type={currentTab}
      />
      <ModalCMS
        open={openCreateModal}
        handler={createPromoCodeHandler}
        onSubmit={onCreateModalSubmit}
        type={currentTab}
      />
    </div>
  );
};

export default CMSPage;
