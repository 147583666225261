import dayjs from "dayjs"
import isoWeek from "dayjs/plugin/isoWeek"
import quarterOfYear from "dayjs/plugin/quarterOfYear"
import customParseFormat from "dayjs/plugin/customParseFormat"

dayjs.extend(customParseFormat)
dayjs.extend(isoWeek)
dayjs.extend(quarterOfYear)

export default dayjs
