import { useTranslation } from "react-i18next"
import Modal from "./Modal"
import { useFormik } from "formik"
import * as Yup from "yup"
import InputField from "components/inputs/InputField"
import { toast } from "react-toastify"

type DeclineForm = {
  reason: string
}

type ModalDeclineActionProps = {
  data: any
  handler: any
  text: string
  actionText: string
  actionHandler: (id: string, reason: string) => void
}

export const ModalDeclineAction = ({ data, handler, text, actionText, actionHandler }: ModalDeclineActionProps) => {
  const { t } = useTranslation("landing", { useSuspense: false })

  const formik = useFormik<DeclineForm>({
    initialValues: {
      reason: ""
    },
    onSubmit: async (values: DeclineForm, formikHelpers): Promise<void> => {
      try {
        await actionHandler(data.id, values.reason)
        handler()
        formikHelpers.resetForm()
      } catch (err: any) {
        toast.error(err?.message || "Failed")
      }
    },
    validationSchema: Yup.object({
      reason: Yup.string().required()
    })
  })

  return (
    <Modal open={!!data} handler={handler}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <div className="relative flex flex-col justify-between modal-box">
          {text && <p className="pb-4">{text}</p>}
          <InputField
            id="reason"
            title="Decline Reason"
            value={formik.values.reason}
            onChange={formik.handleChange}
            invalid={!!(formik.errors.reason && formik.touched.reason)}
            altLabelTwo={formik.touched.reason ? formik.errors.reason : ""}
          />
          <div className="modal-action">
            <button className="btn btn-secondary" onClick={handler} type="button">
              {t("cancel")}
            </button>
            <button className="btn btn-primary" type="submit">
              {actionText}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
}
