import { DispatchWithoutAction, useState } from "react"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import Table from "components/tables/Table"
import userService from "services/user/user.service"
import DropdownMenu from "components/inputs/DropdownMenu"
import ModalConfirmAction from "components/modals/ModalConfirmAction"
import transactionService from "services/transaction/transaction.service"
import { GameStatusType, SortOrderType, SortOrderBy } from "constants/eums"
import { formatFiatCurrency } from "util/formatter"
import {
  tableUserBets,
  tableUserReferrals,
  tableUserRewardGames,
  tableUserTransactions,
  transactionStatuses,
  transactionTypes
} from "constants/data"
import { GetSingleUser } from "services/user/type"
import clsx from "clsx"
import { UserAccountActivityContent, UserBonusContent } from "features/user/tab-contents"
import { Maybe } from "types/common"
import SingleOptTimeRangePicker from "components/date/SingleOptTimePeriodPicker"

enum EnumTab {
  Bets = "Bets",
  Transactions = "Transactions",
  Referrals = "Referrals",
  "Reward Games" = "Reward Games",
  Bonuses = "Bonuses",
  "Activity Logs" = "Activity Logs"
}

interface UserDetailTabsProps {
  forceUpdate: DispatchWithoutAction
  tableUpdatedAt: number
  updateKey: any
  userResult: Maybe<GetSingleUser>
  onLoad: () => void
  setEditRow: (v: any) => void
  setOpenCreateModal: (v: boolean) => void
  setTableUpdatedAt: (v: number) => void
  setUserResult: (v: any) => void
}

export const UserTabContents = ({
  forceUpdate,
  tableUpdatedAt,
  updateKey,
  userResult,
  onLoad,
  setEditRow,
  setOpenCreateModal,
  setTableUpdatedAt,
  setUserResult
}: UserDetailTabsProps) => {
  const { t } = useTranslation(["landing"], { useSuspense: false })
  const [type, setType] = useState<string>("")
  const [status, setStatus] = useState<string>("")
  const [currentTab, setCurrentTab] = useState<EnumTab>(EnumTab.Bets)
  const [timeframe, setTimeframe] = useState<(Date | null)[]>([])
  const [confirmRow, setConfirmRow] = useState<any>(null)
  const [declineRow, setDeclineRow] = useState<any>(null)

  const handleChangeTimeFrame = (dates: (Date | null)[]) => setTimeframe(dates)

  const onEditRow = (row: any): void => {
    setEditRow(row)
  }

  const actions = [
    {
      name: t("delete"),
      condition: (row: any) => row?.status === GameStatusType.Claim,
      handler: onEditRow
    }
  ]

  const confirmRowHandler = (): void => {
    setConfirmRow(null)
    setTableUpdatedAt(Date.now())
  }

  const onConfirmTransaction = async (): Promise<void> => {
    const response = await transactionService.confirmTransaction(confirmRow?.id)
    if (response) {
      toast.success(
        t("success_action_transaction", {
          actionText: t("confirmed")
        }) as string
      )
    }
  }

  const onOpenConfirmRow = (row: any): void => {
    setConfirmRow(row)
  }

  const onOpenDeclineRow = (row: any): void => {
    setDeclineRow(row)
  }

  const declineRowHandler = (): void => {
    setDeclineRow(null)
    setTableUpdatedAt(Date.now())
  }

  const onDeclineTransaction = async (): Promise<void> => {
    const response = await transactionService.declineTransaction(declineRow?.id)
    if (response) {
      toast.success(t("success_action_transaction", { actionText: t("declined") }) as string)
    }
  }

  const transactionActions = [
    {
      name: t("decline"),
      condition: (row: any) => row?.status === "UNCONFIRMED" && row?.type === "WITHDRAWAL",
      handler: onOpenDeclineRow,
      buttonColor: "btn-secondary"
    },
    {
      name: t("confirm"),
      condition: (row: any) => row?.status === "UNCONFIRMED" && row?.type === "WITHDRAWAL",
      handler: onOpenConfirmRow
    }
  ]

  const handleCancelBonusSuccess = () => {
    setUserResult((prev: any) => (prev ? { ...prev, busd_balance: "", coins: { ...prev.coins, B_USD: 0 } } : prev))
    onLoad()
    forceUpdate()
  }

  return userResult ? (
    <>
      <div className="flex items-center justify-center">
        <div className="tabs">
          {Object.keys(EnumTab).map((tab) => (
            <a
              key={`user-tab-${tab}`}
              onClick={() => setCurrentTab(tab as EnumTab)}
              className={clsx("tab tab-bordered", {
                "tab-active": currentTab === tab
              })}
            >
              {tab}
            </a>
          ))}
        </div>
      </div>

      {currentTab !== EnumTab["Reward Games"] ? (
        <div className="max-w-md">
          <SingleOptTimeRangePicker onTimeframeChange={handleChangeTimeFrame} />
        </div>
      ) : null}

      {currentTab === EnumTab.Bets && (
        <Table
          title={t("bets")}
          handler={userService.getUserBets}
          columns={tableUserBets}
          id={userResult.id}
          filters={{ createdAt: timeframe }}
        />
      )}

      {currentTab === EnumTab.Transactions && (
        <>
          <div className="flex flex-row flex-1 max-w-md space-x-4">
            <DropdownMenu title="Transaction Type" options={transactionTypes} value={type} onChange={(e: any) => setType(e.target.value)} />
            <DropdownMenu
              title="Transaction Status"
              options={transactionStatuses}
              value={status}
              onChange={(e: any) => setStatus(e.target.value)}
            />
          </div>
          <Table
            title={t("transactions")}
            handler={userService.getUserTransactions}
            columns={tableUserTransactions}
            id={userResult.id}
            filters={{ type, status, createdAt: timeframe }}
            actions={transactionActions}
          />
          <ModalConfirmAction
            data={confirmRow}
            handler={confirmRowHandler}
            text={t("modal_conformation_transaction", {
              actionText: t("confirm"),
              usd_amount: formatFiatCurrency(confirmRow?.usd_amount),
              user: confirmRow?.user
            })}
            actionText={t("confirm")}
            actionHandler={onConfirmTransaction}
          />
          <ModalConfirmAction
            data={declineRow}
            handler={declineRowHandler}
            text={t("modal_conformation_transaction", {
              actionText: t("decline"),
              usd_amount: formatFiatCurrency(declineRow?.usd_amount),
              user: declineRow?.user
            })}
            actionText={t("decline")}
            actionHandler={onDeclineTransaction}
          />
        </>
      )}

      {currentTab === EnumTab.Referrals && (
        <Table
          title={t("referrals")}
          handler={userService.getUserReferrals}
          columns={tableUserReferrals}
          filters={{ createdAt: timeframe }}
          id={userResult.id}
        />
      )}

      {currentTab === EnumTab["Reward Games"] && (
        <Table
          title={t("reward_games")}
          handler={userService.getUserRewardGames}
          columns={tableUserRewardGames}
          id={userResult.id}
          actions={actions}
          updatedAt={tableUpdatedAt}
          buttonHander={() => setOpenCreateModal(true)}
          buttonText={t("create")}
          defaultSortOrder={SortOrderType.ASC}
          defaultSortOrderBy={SortOrderBy.ID}
        />
      )}

      {currentTab === EnumTab.Bonuses && (
        <UserBonusContent
          userId={userResult.id}
          onSuccessCancel={handleCancelBonusSuccess}
          key={`user-bonus-tale-wrapper-${updateKey}`}
          filters={{ createdAt: timeframe }}
        />
      )}

      {currentTab === EnumTab["Activity Logs"] && <UserAccountActivityContent userId={userResult.id} filters={{ createdAt: timeframe }} />}
    </>
  ) : null
}
