import { CreatePromoCodePayload, EditPromoCodePayload, IPromoCodeService, PromoCodeActionResponse, PromoCodesTableResponse } from "./type"
import useApi from "util/api"
import { REQ } from "constants/api/requests"
import { IResponse } from "constants/interfaces"
import { toast } from "react-toastify"

export class PromoCodeService implements IPromoCodeService {
  public async getPromoCodesTable(
    page: number,
    pageSize: number,
    id: number | undefined,
    { code }: { code: string },
    sortRow?: string,
    sortOrder?: string
  ): Promise<PromoCodesTableResponse | void> {
    try {
      const params = {
        page,
        pageSize,
        code,
        sortRow,
        sortOrder
      }

      const { data } = await useApi.get<IResponse<PromoCodesTableResponse>>(REQ.ADMIN_DASHBOARD.PROMO_CODES.GET, { params })

      if (data?.success) {
        return data?.response
      }
    } catch (err: any) {
      toast.error(err?.message ?? "Failed to get promo code list")
    }
  }

  public async createPromoCode(payload: CreatePromoCodePayload): Promise<PromoCodeActionResponse | void> {
    const { data } = await useApi.post<IResponse<PromoCodeActionResponse>>(REQ.ADMIN_DASHBOARD.PROMO_CODES.CREATE, payload)

    if (data?.success) {
      return data?.response
    }
  }

  public async editPromoCode(id: string, payload: EditPromoCodePayload): Promise<PromoCodeActionResponse | void> {
    const { data } = await useApi.put<IResponse<PromoCodeActionResponse>>(
      REQ.ADMIN_DASHBOARD.PROMO_CODES.UPDATE.replace(":id", id),
      payload
    )

    if (data?.success) {
      return data?.response
    }
  }
}

export default new PromoCodeService()
