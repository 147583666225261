import useApi from "util/api"
import { IGameService } from "./type"
import { REQ } from "constants/api/requests"
import { IResponse } from "constants/interfaces"

class GameService implements IGameService {
  public async getGameProviders(): Promise<void | []> {
    const { data } = await useApi.get<IResponse<[]>>(REQ.ADMIN_DASHBOARD.GAMES.FREE_SPIN_GAME_PROVIDERS)

    if (data?.success) {
      return data?.response
    }
  }

  public async getFreeSpinGames(provider?: string, query?: string): Promise<void | []> {
    const params = {
      provider: provider || null,
      query
    }

    const { data } = await useApi.get<IResponse<[]>>(REQ.ADMIN_DASHBOARD.GAMES.FREE_SPIN_GAMES, { params })

    if (data?.success) {
      return data?.response
    }
    return []
  }
}

export default new GameService()
