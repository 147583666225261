// import { useState } from "react"
import { GetSingleUser } from "services/user/type"
import { CardWrapper } from "./CardWrapper"
// import userService from "services/user/user.service"
// import { toast } from "react-toastify"
// import { useIsMounted } from "hooks/useIsMounted"

type UserBalanceCardProps = {
  user: GetSingleUser
  reloadUser: () => Promise<void>
}
export const UserBalanceCard = ({ user }: UserBalanceCardProps) => {
  // const isMounted = useIsMounted()
  // const [swapCoin, setSwapCoin] = useState<Coin | undefined>()
  // const handleSwap = (coin: Coin) => async () => {
  //   try {
  //     setSwapCoin(coin)
  //     await userService.swapUserBalance(user.id, { coin })
  //     if (!isMounted()) return

  //     setSwapCoin(undefined)
  //     reloadUser()
  //   } catch (err: any) {
  //     toast.error(err?.data?.message || err?.message || "Something went wrong.")
  //     if (!isMounted()) return

  //     setSwapCoin(undefined)
  //   }
  // }

  return (
    <CardWrapper>
      <span>
        Total Balance: <span className="text-accent">{user.balance}</span>
      </span>
      {/* <span>
        ETH: <span className="text-accent">{user.eth_balance}</span>
        <button className="btn btn-secondary btn-xs" onClick={handleSwap(Coin.ETH)} disabled={Boolean(swapCoin)}>
          {swapCoin === Coin.ETH && <span className="loading loading-spinner loading-xs" />}
          Swap with USD
        </button>
      </span>
      <span>
        BTC: <span className="text-accent">{user.btc_balance}</span>
        <button className="btn btn-secondary btn-xs" onClick={handleSwap(Coin.BTC)} disabled={Boolean(swapCoin)}>
          {swapCoin === Coin.BTC && <span className="loading loading-spinner" />}
          Swap with USD
        </button>
      </span>
      <span>
        LTC: <span className="text-accent">{user.ltc_balance}</span>
        <button className="btn btn-secondary btn-xs" onClick={handleSwap(Coin.LTC)} disabled={Boolean(swapCoin)}>
          {swapCoin === Coin.LTC && <span className="loading loading-spinner" />}
          Swap with USD
        </button>
      </span>
      <span>
        USDT: <span className="text-accent">{user.usdt_balance}</span>
        <button className="btn btn-secondary btn-xs" onClick={handleSwap(Coin.USDT)} disabled={Boolean(swapCoin)}>
          {swapCoin === Coin.USDT && <span className="loading loading-spinner" />}
          Swap with USD
        </button>
      </span> */}

      <span>
      USD: <span className="text-accent">{user.usd_balance}</span>
      </span>
      <span>
        Bonus: <span className="text-accent">{user.busd_balance}</span>
      </span>
    </CardWrapper>
  )
}
